export const MONTHLY_INVESTMENT_MIN = 0;
export const MONTHLY_INVESTMENT_DEFAULT = 100;
export const MONTHLY_INVESTMENT_MAX = 15000;
export const MONTHLY_INVESTMENT_STEP = 100;

export const INITIAL_INVESTMENT_MIN = 500;
export const INITIAL_INVESTMENT_DEFAULT = 3000;
export const INITIAL_INVESTMENT_MAX = 100000;
export const INITIAL_INVESTMENT_STEP = 500;

export const PAST_DATA_YEAR_COUNT = 5;
export const FORECAST_YEAR_COUNT = 2;
