import withTranslation from 'next-translate/withTranslation';

import { Box, Image, LinkToFaqPage, SkeletonTypography, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import {
  EActionAttribute,
  ESize,
  ETypographyVariant,
  EZIndexName,
  WithTranslation,
} from '@core/type';
import { EFAQCategory, EQueryKey, FAQCategoryEntity } from '@core/type/api';
import { FAQBlock } from '@core/widget';

const HomepageFaqBlockContainer = ({
  i18n: { t },
  category,
}: WithTranslation & { category: EFAQCategory }) => {
  const { isPhone, mediaQuery } = useContextMediaQuery();
  const isLargeDesktop: boolean = mediaQuery === ESize.XL;

  const {
    data: faqQuestions,
    isError,
    isLoading,
  } = useQueryApi<FAQCategoryEntity, { category: EFAQCategory }>(EQueryKey.FAQ_CATEGORY, {
    category,
  });

  if (isError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <Box marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  return (
    <>
      {faqQuestions && (
        <Box
          marginTop={isPhone ? '24px' : '104px'}
          position={'relative'}
          zIndex={EZIndexName.OVER_DEFAULT}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            marginTop={isPhone ? '110px' : '150px'}
          >
            <Box maxWidth={'750px'}>
              <Typography
                as={EActionAttribute.H2}
                variant={ETypographyVariant.H1}
                marginBottom={'24px'}
              >
                {t('home.faqTitle')}
              </Typography>

              <Typography variant={ETypographyVariant.BODY1}>{t('home.faqDesc')}</Typography>
            </Box>

            {!isPhone && (
              <Box display={'flex'} alignItems={'flex-end'} marginLeft={'16px'} flexShrink={'0'}>
                <LinkToFaqPage />
              </Box>
            )}
          </Box>

          <Box
            marginTop={'56px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <FAQBlock
              faqQuestions={faqQuestions}
              category={category}
              as={EActionAttribute.SECTION}
              maxWidth={'750px'}
              margin={'0'}
            />

            {!isPhone && (
              <Box
                minWidth={isLargeDesktop ? '285px' : '244px'}
                height={isLargeDesktop ? '398px' : '340px'}
                position={'relative'}
                right={'-24px'}
              >
                <Image
                  src={getStaticPathImage('homepage/silver-1kilo.png')}
                  blurDataURL={getStaticPathImageBlurData(
                    EFilenameBlur['homepage/silver-1kilo.png'],
                  )}
                  alt={t('home.silverAlt')}
                  width={285}
                  height={398}
                />
              </Box>
            )}
          </Box>

          {isPhone && (
            <Box display={'flex'} justifyContent={'flex-end'} marginTop={'56px'}>
              <LinkToFaqPage />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const HomepageFaqBlock = withTranslation(HomepageFaqBlockContainer, Translate.common.WIDGET);
