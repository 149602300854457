import { ReactNode } from 'react';

import { BaseBoxProps, Box, Typography } from '@core/component';
import { EColor, EFontWeight, ETypographyVariant } from '@core/type';

export const Chip = ({
  isActive,
  children,
  ...boxProps
}: {
  children: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
} & BaseBoxProps) => (
  <Box
    {...boxProps}
    display="inline-block"
    borderRadius="8px"
    backgroundColor={isActive ? EColor.INFO : undefined}
    padding="8px 7px"
    cursor="pointer"
  >
    <Typography
      variant={ETypographyVariant.BODY3}
      fontWeight={isActive ? EFontWeight.BOLD : undefined}
    >
      {children}
    </Typography>
  </Box>
);
