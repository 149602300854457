export enum EPricing {
  STORAGE_100 = 'storage100',
  STORAGE_10K = 'storage10k',
  STORAGE_100K = 'storage100k',
  LIMIT_15K = 'limit15k',
  LIMIT_100K = 'limit100k',
  PAY_PER_MONTH = 'payPerMonth',
  MANUAL_RATE = 'manualRate',
  DYNAMIC_RATE = 'dynamicRate',
}

export type PricingValuesType = {
  [key in EPricing]: number;
};

export type PricingType<T extends Partial<EPricing>> = {
  [key in T]: T;
};

export type PricingRateType = keyof PricingType<EPricing.MANUAL_RATE | EPricing.DYNAMIC_RATE>;

export type PricingLimitType = keyof PricingType<EPricing.LIMIT_15K | EPricing.LIMIT_100K>;

export type PricingStorageType = keyof PricingType<
  EPricing.STORAGE_10K | EPricing.STORAGE_100K | EPricing.STORAGE_100
>;

export type CurrencyRateFormatter = (rateKey: PricingRateType, valueInCHF: number) => number | null;
