import { ECurrency, ECurrencySlug } from '@core/type';

export const currencyIsoToCurrencySlug = {
  [ECurrency.EUR]: ECurrencySlug.EURO,
  [ECurrency.USD]: ECurrencySlug.DOLLAR,
  [ECurrency.CHF]: ECurrencySlug.SWISS_FRANC,
  [ECurrency.GBP]: ECurrencySlug.POUND_STERLING,
};

export const currencySlugToCurrencyIso = {
  [ECurrencySlug.EURO]: ECurrency.EUR,
  [ECurrencySlug.DOLLAR]: ECurrency.USD,
  [ECurrencySlug.SWISS_FRANC]: ECurrency.CHF,
  [ECurrencySlug.POUND_STERLING]: ECurrency.GBP,
};
