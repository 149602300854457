import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';
import { ReactNode, useMemo, useState } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { Box, ButtonRadioSwitch, NextLinkStyledAsLink, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import { getSanityPageRoute } from '@core/sanity/utils';
import {
  EColor,
  EDateFormat,
  EDateTimezone,
  EFontWeight,
  EMetal,
  ETypographyVariant,
  WithThemeProps,
  WithTranslation,
} from '@core/type';
import {
  EQueryKey,
  ETableSpotPriceCarat,
  ETableSpotPriceCategory,
  ETableSpotPriceCurrency,
  ETableSpotWeights,
  SpotPriceCaratsRequest,
  SpotPriceCaratsResponse,
  SpotPriceCurrenciesRequest,
  SpotPriceCurrenciesResponse,
  SpotPriceWeightsRequest,
  SpotPriceWeightsResponse,
  SpotPriceWeightUnitType,
  tableSpotWeightToGramWeight,
} from '@core/type/api';
import { ESanityField, SanityLinkObjProps, SanityWidget } from '@core/type/sanity';
import { formatDate, getPaletteHandlers, uppercaseKeysInResObj } from '@core/util';

import { DYNAMIC_TRANSLATIONS } from './constants';
import {
  WidgetTableSpotPriceDailyStatsComponentEntity,
  WidgetTableSpotPriceDailyStatsProps,
} from './interface-table-spot-price-daily-stats';
import {
  formatCaratsLinks,
  formatCaratTableData,
  formatRowTranslationByKey,
  formatTableData,
  formatWeightTableData,
} from './utils';

const DEFAULT_TABLE_WEIGHTS: Record<ETableSpotWeights, boolean> = {
  [ETableSpotWeights.OUNCE]: true,
  [ETableSpotWeights.OUNCE5]: false,
  [ETableSpotWeights.OUNCE10]: false,
  [ETableSpotWeights.OUNCE100]: false,
  [ETableSpotWeights.GRAM]: true,
  [ETableSpotWeights.GRAM5]: false,
  [ETableSpotWeights.GRAM10]: false,
  [ETableSpotWeights.GRAM100]: false,
  [ETableSpotWeights.KILO]: true,
  [ETableSpotWeights.KILO5]: false,
  [ETableSpotWeights.KILO10]: false,
  [ETableSpotWeights.KILO100]: false,
  [ETableSpotWeights.TON]: true,
};

const ROUNDING = 2;

const WidgetTableSpotPriceDailyStatsBase = ({
  tabs,
  links,
  metalIso,
  weightUnit,
  weights,
  i18n: { t },
  internalLinks,
}: WidgetTableSpotPriceDailyStatsProps & WithTranslation) => {
  const { language } = useLanguage();

  const [tab, setTab] = useState<ETableSpotPriceCategory>(tabs[0]);

  const { data: caratsData } = useQueryApi<
    SpotPriceCaratsResponse,
    Omit<SpotPriceCaratsRequest, 'currencyIso'>
  >(
    EQueryKey.SPOT_PRICE_CARATS,
    { metalIso, weightUnit },
    {
      enabled:
        tabs.some((tab) => tab === ETableSpotPriceCategory.CARATS) &&
        tab === ETableSpotPriceCategory.CARATS &&
        Boolean(weightUnit) &&
        Boolean(metalIso),
    },
  );

  const { data: currenciesData } = useQueryApi<
    SpotPriceCurrenciesResponse,
    SpotPriceCurrenciesRequest
  >(
    EQueryKey.SPOT_PRICE_CURRENCIES,
    { metalIso, weightUnit },
    {
      enabled:
        tabs.some((tab) => tab === ETableSpotPriceCategory.CURRENCIES) &&
        tab === ETableSpotPriceCategory.CURRENCIES &&
        Boolean(weightUnit) &&
        Boolean(metalIso),
    },
  );

  const { data: weightsData } = useQueryApi<
    SpotPriceWeightsResponse,
    Omit<SpotPriceWeightsRequest, 'currencyIso'>
  >(
    EQueryKey.SPOT_PRICE_WEIGHTS,
    { metalIso },
    {
      enabled:
        tabs.some((tab) => tab === ETableSpotPriceCategory.WEIGHT) &&
        tab === ETableSpotPriceCategory.WEIGHT &&
        Boolean(metalIso),
    },
  );

  const tableData = {
    [ETableSpotPriceCategory.CARATS]: caratsData,
    [ETableSpotPriceCategory.CURRENCIES]: currenciesData,
    [ETableSpotPriceCategory.WEIGHT]: weightsData,
  };

  const { currency } = useContextCurrency();

  const tableRows = useMemo(() => {
    if (tab === ETableSpotPriceCategory.CARATS) {
      if (!caratsData) {
        return null;
      }
      return formatCaratTableData<SpotPriceCaratsResponse, ETableSpotPriceCarat>(
        caratsData,
        language,
        currency,
      ).sort(({ key: keyA }, { key: keyB }) => keyB - keyA);
    }

    if (tab === ETableSpotPriceCategory.CURRENCIES) {
      if (!currenciesData) {
        return null;
      }

      return formatTableData<SpotPriceCurrenciesResponse, ETableSpotPriceCurrency>(
        {
          ...currenciesData,
          table: uppercaseKeysInResObj<
            ETableSpotPriceCurrency,
            SpotPriceCurrenciesResponse['table']
          >(currenciesData.table),
        },
        language,
      );
    }

    if (tab === ETableSpotPriceCategory.WEIGHT) {
      if (!weightsData) {
        return null;
      }

      return formatWeightTableData(
        weightsData,
        weights ?? DEFAULT_TABLE_WEIGHTS,
        language,
        currency,
      ).sort((a, b) => tableSpotWeightToGramWeight[a.key] - tableSpotWeightToGramWeight[b.key]);
    }

    return null;
  }, [tab, caratsData, currenciesData, weightsData, weights, currency]);

  if (!tab) {
    return null;
  }

  const getLinkHref = <T extends ETableSpotPriceCategory>(
    tab: T,
    key: keyof WidgetTableSpotPriceDailyStatsProps['links'][T],
  ) => links?.[tab]?.[key];

  const getInternalLinkObj = <T extends ETableSpotPriceCategory>(
    tab: T,
    key: keyof WidgetTableSpotPriceDailyStatsProps['internalLinks'][T],
  ) => internalLinks?.[tab]?.[key] as SanityLinkObjProps;

  return (
    <Box overflowX={'hidden'} maxWidth={'calc(100vw - 40px)'}>
      <Box display={'flex'} justifyContent={'center'} marginBottom={'48px'}>
        {tabs?.length > 1 && (
          <ButtonRadioSwitch<ETableSpotPriceCategory>
            value={tab}
            name={'tableMetalPriceCategory'}
            options={tabs.map((category) => ({
              title: t(DYNAMIC_TRANSLATIONS[category]),
              value: category,
            }))}
            onChange={({ target: { value } }) => setTab(value as ETableSpotPriceCategory)}
          />
        )}
      </Box>
      <Typography
        color={EColor.SECONDARY}
        variant={ETypographyVariant.CAPTION2}
        marginBottom={'16px'}
      >
        {tableData[tab]?.createdAt && (
          <Trans
            i18nKey={`${Translate.common.WIDGET}:tableMetalPrice.lastUpdate`}
            values={{
              date: formatDate(
                tableData[tab].createdAt,
                language,
                EDateFormat.ddMMyyyyhhmm,
                EDateTimezone.EUROPE_PARIS,
              ),
            }}
          />
        )}
      </Typography>

      <Box overflowX={'auto'}>
        <StyledTable>
          <StyledTHead>
            <StyledTR>
              <StyledTH>{t(DYNAMIC_TRANSLATIONS[tab])}</StyledTH>
              <StyledTH>{t('tableMetalPrice.currentPrice')}</StyledTH>
              <StyledTH>{t('tableMetalPrice.dayMin')}</StyledTH>
              <StyledTH>{t('tableMetalPrice.dayMax')}</StyledTH>
              <StyledTH>{t('tableMetalPrice.dayAverage')}</StyledTH>
              <StyledTH>{t('tableMetalPrice.dailyPerformance')}</StyledTH>
            </StyledTR>
          </StyledTHead>
          <StyledTBody>
            {tableRows?.map(
              (
                { key, rowData: { currentPrice, dayMin, dayMax, dayAverage, dayPerformance } },
                index,
              ) => {
                const isPerformancePositive = dayPerformance >= 0;
                const linkHref = getLinkHref(
                  tab,
                  key as keyof WidgetTableSpotPriceDailyStatsProps['links'][typeof tab],
                ) as string;
                const linkObj = getInternalLinkObj(
                  tab,
                  key as keyof WidgetTableSpotPriceDailyStatsProps['internalLinks'][typeof tab],
                );

                let href: string = linkHref;

                if (linkObj?.[ESanityField.INTERNAL_LINK]?._ref) {
                  const { keyRoute, language, slug, metalIso } = linkObj;

                  href = getSanityPageRoute(keyRoute, language, slug, metalIso);
                }

                return (
                  <StyledTR key={`${index}}`}>
                    <StyledTH>
                      <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        {href ? (
                          <Typography>
                            <NextLinkStyledAsLink
                              fontWeight={EFontWeight.BOLD}
                              href={href}
                              color={EColor.ACCENT}
                              locale={false}
                            >
                              {formatRowTranslationByKey(key, tab, t)}
                            </NextLinkStyledAsLink>
                          </Typography>
                        ) : (
                          <Typography fontWeight={EFontWeight.BOLD}>
                            {formatRowTranslationByKey(key, tab, t)}
                          </Typography>
                        )}
                      </Box>
                    </StyledTH>
                    <StyledTD>
                      <TableCell>{currentPrice}</TableCell>
                    </StyledTD>
                    <StyledTD>
                      <TableCell>{dayMin}</TableCell>
                    </StyledTD>
                    <StyledTD>
                      <TableCell>{dayMax}</TableCell>
                    </StyledTD>
                    <StyledTD>
                      <TableCell>{dayAverage}</TableCell>
                    </StyledTD>
                    <StyledTD>
                      <TableCell>
                        {isNaN(Number(dayPerformance)) || dayPerformance === null ? (
                          <Typography fontWeight={EFontWeight.BOLD}>-</Typography>
                        ) : (
                          <Typography
                            fontWeight={EFontWeight.BOLD}
                            color={isPerformancePositive ? EColor.SUCCESS : EColor.DANGER}
                          >
                            {isPerformancePositive ? '+' : ''}
                            {dayPerformance.toFixed(ROUNDING)}%
                          </Typography>
                        )}
                      </TableCell>
                    </StyledTD>
                  </StyledTR>
                );
              },
            )}
          </StyledTBody>
        </StyledTable>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const WidgetTableSpotPriceDailyStats = withTranslation(
  WidgetTableSpotPriceDailyStatsBase,
  Translate.common.WIDGET,
);

export const getWidgetTableSpotPriceDailyStats = (section: SanityWidget) => {
  const props = section as WidgetTableSpotPriceDailyStatsComponentEntity;

  return (
    <WidgetTableSpotPriceDailyStats
      metalIso={props?.apiParams?.metalIso as EMetal}
      weightUnit={props?.apiParams?.weightUnit as SpotPriceWeightUnitType}
      tabs={props?.tabs?.map((tab) => tab?._type as ETableSpotPriceCategory) || []}
      links={{
        ...props?.links,
        ...(props?.links?.carats && {
          [ETableSpotPriceCategory.CARATS]: formatCaratsLinks(props?.links?.carats),
        }),
      }}
      internalLinks={{
        ...props?.internalLinks,
        ...(props?.internalLinks?.carats && {
          [ETableSpotPriceCategory.CARATS]: formatCaratsLinks<SanityLinkObjProps>(
            props?.internalLinks?.carats,
          ),
        }),
      }}
      weights={props.weights}
    />
  );
};

const StyledTHead = styled.thead(({ theme: { palette } }: WithThemeProps) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);

  return css`
    height: 34px;
    background-color: ${getBackgroundColor({
      semanticType: EColor.SECONDARY,
      variant: EColor.LIGHT,
    })};

    ${StyledTH}:first-of-type {
      padding-left: 20px;
    }

    ${StyledTH}:last-of-type {
      padding-right: 20px;
    }

    ${StyledTH} {
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 34px;
      font-size: 14px;
    }

    ${StyledTH} + ${StyledTH} {
      padding-left: 8px;
    }
  `;
});

const StyledTBody = styled.tbody(({ theme: { palette } }: WithThemeProps) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);

  return css`
    ${StyledTR}:nth-child(even) {
      background-color: ${getBackgroundColor(EColor.INFO)};
    }
  `;
});

const StyledTH = styled.th(
  ({
    theme: {
      typography: { fontWeight },
    },
  }: WithThemeProps) => {
    return {
      position: 'relative',
      textAlign: 'left',
      fontWeight: fontWeight[EFontWeight.BOLD],
    } as CSSObject;
  },
);

const StyledTR = styled.tr(({ theme: { palette } }: WithThemeProps) => {
  const { getColor } = getPaletteHandlers(palette);

  return css`
    border-bottom: 1px solid ${getColor({ commonType: EColor.GRAY, intensity: EColor.A200 })};
  `;
});

const StyledTD = styled.td`
  &:first-of-type {
    padding-left: 8px;
  }
`;

const StyledTable = styled.table`
  width: 100%;

  ${StyledTH}:first-of-type {
    padding-left: 20px;
  }

  ${StyledTH}:last-child,
  ${StyledTD}:last-child {
    padding-right: 20px;
  }

  ${StyledTH},
  ${StyledTD} {
    height: 50px;
    white-space: nowrap;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  ${StyledTH} {
    line-height: 34px;
    font-size: 14px;
  }

  ${StyledTH} + ${StyledTH},
  ${StyledTD} + ${StyledTD} {
    padding-left: 8px;
  }
`;

const TableCell = ({ children }: { children: ReactNode }) => (
  <Box height={'100%'} display={'flex'} alignItems={'center'}>
    {children}
  </Box>
);
