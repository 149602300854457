import { Accordion, Box, Container, EditorContent, Typography } from '@core/component';
import {
  EAccordion,
  EAccordionClosingType,
  EActionAttribute,
  EPosition,
  ETypographyVariant,
} from '@core/type';

import { FAQBlockProps } from './interface-faq';

export const FAQBlock = ({ category, title, faqQuestions, ...restProps }: FAQBlockProps) => {
  return (
    faqQuestions?.faqQuestions?.length > 0 && (
      <Container
        gutter={0}
        maxWidth="764px"
        display="flex"
        flexDirection="column"
        gap="16px"
        {...restProps}
        aria-label={category}
      >
        {title}
        {faqQuestions?.faqQuestions.map(({ name, content }, index) => (
          <Accordion
            key={name + index.toString()}
            titleContent={
              <Typography as={EActionAttribute.H3} variant={ETypographyVariant.H5}>
                {name}
              </Typography>
            }
            type={EAccordion.FAQ}
            closingType={EAccordionClosingType.FULL_CLICK}
            iconPosition={EPosition.TOP}
          >
            <Box paddingTop="16px">
              <EditorContent content={content} />
            </Box>
          </Accordion>
        ))}
      </Container>
    )
  );
};
