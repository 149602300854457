import styled, { css } from 'styled-components';

import { EFontWeight, WithThemeProps } from '@core/type';

const getCommonStyles = (isMobile: boolean) => css`
  margin: 20px 0;
  padding-left: ${isMobile ? '28px' : '78px'};

  li {
    padding-left: ${isMobile ? '13px' : '10px'};
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const UnorderedList = styled.ul(
  ({ isMobile }: { isMobile: boolean }) => css`
    ${getCommonStyles(isMobile)}
    li::marker {
      font-size: 22px;
    }
  `,
);

export const OrderedList = styled.ol(
  ({
    theme: {
      typography: { fontWeight },
    },
    isMobile,
  }: WithThemeProps & {
    isMobile: boolean;
  }) => css`
    ${getCommonStyles(isMobile)}
    li::marker {
      font-size: 16px;
      font-weight: ${fontWeight[EFontWeight.BOLD]};
    }
  `,
);
