import { createContext } from 'react';

import type { HookStore, WeightUnitHook, WeightUnitState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const weightUnitContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, WeightUnitState, WeightUnitHook>>(null);

export const useContextWeightUnit = () => {
  return useFnContext(weightUnitContext);
};

export function useStoreWeightUnit<Selector>(selector: keyof WeightUnitState) {
  return useStoreContext<Selector, WeightUnitState, WeightUnitHook>(selector, weightUnitContext);
}
