import { useEffect, useRef, useState } from 'react';

import { themeMediaQueries } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useCarouselSteps } from '@core/hook';
import { CollectionsProductGroupsEntity } from '@core/type/api';

export const useCarouselProductGroups = (collections: CollectionsProductGroupsEntity[]) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const slidesWidthRef = useRef<number[]>([]);

  const [isStatic, setIsStatic] = useState<boolean>(false);

  const { isPhone, mediaQuery } = useContextMediaQuery();
  const {
    currentStep,
    onPrevious,
    onNext,
    onClick: setSlide,
    ...restCarouselProps
  } = useCarouselSteps();

  const totalSteps = collections?.length;

  useEffect(() => {
    if (slidesWidthRef?.current) {
      setIsStatic(
        !isPhone &&
          themeMediaQueries[mediaQuery] >
            slidesWidthRef?.current.reduce((width, acc) => acc + width, 0),
      );
    }
  }, [mediaQuery, slidesWidthRef?.current]);

  useEffect(() => {
    setSlide(0);
  }, [mediaQuery]);

  const setSlidesWidth = (index: number, width: number) => {
    slidesWidthRef.current[index] = width;
  };

  return {
    ref: carouselRef,
    currentStep,
    collections,
    totalSteps,
    isStatic,
    setSlidesWidth,
    slidesWidth: slidesWidthRef.current,
    setSlide,
    ...restCarouselProps,
  };
};
