import Trans from 'next-translate/Trans';
import styled, { CSSObject, css } from 'styled-components';

import { Box, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useLanguage, useQueryApi } from '@core/hook';
import {
  EColor,
  EDateFormat,
  EDateTimezone,
  EFontWeight,
  EMetal,
  ETypographyVariant,
  WithThemeProps,
} from '@core/type';
import {
  EQueryKey,
  ETablePeriod,
  SpotPriceHistoryRequest,
  SpotPriceHistoryResponse,
} from '@core/type/api';
import { SanityWidget } from '@core/type/sanity';
import { formatDate, getPaletteHandlers } from '@core/util';

import { DYNAMIC_TRANSLATIONS_VALUES, T_PLURAL, T_SINGULAR } from './constants';
import {
  WidgetTableSpotPriceHistoryComponentEntity,
  WidgetTableSpotPriceHistoryProps,
} from './interface-spot-price-history';

export const WidgetTableSpotPriceHistory = ({
  metalIso,
  timeRanges,
}: WidgetTableSpotPriceHistoryProps) => {
  const { language } = useLanguage();

  const { data } = useQueryApi<SpotPriceHistoryResponse, Pick<SpotPriceHistoryRequest, 'metalIso'>>(
    EQueryKey.SPOT_PRICE_HISTORY,
    { metalIso },
  );

  if (!data) {
    return null;
  }

  const periodsData = Object.keys(data.periods)
    .map((periodKey) => ({
      ...DYNAMIC_TRANSLATIONS_VALUES[periodKey as ETablePeriod],
      value: data.periods[periodKey as ETablePeriod],
      periodKey,
    }))
    .filter(({ value, periodKey }) => value !== null && timeRanges[periodKey as ETablePeriod])
    .sort(({ position: posA }, { position: posB }) => posA - posB);

  return (
    <Box overflowX={'hidden'} maxWidth={'calc(100vw - 40px)'}>
      <Typography
        color={EColor.SECONDARY}
        variant={ETypographyVariant.CAPTION1}
        marginBottom={'16px'}
      >
        <Trans
          i18nKey={`${Translate.common.WIDGET}:tableMetalPrice.lastUpdate`}
          values={{
            date: formatDate(
              data?.createdAt,
              language,
              EDateFormat.ddMMyyyyhhmm,
              EDateTimezone.EUROPE_PARIS,
            ),
          }}
        />
      </Typography>
      <Box overflowX={'auto'} borderRadius={'6px 6px 0 0'}>
        <StyledTable>
          <StyledTHead>
            <StyledTR>
              {periodsData.map(({ period, amount }, index) => (
                <StyledTH key={`${index}/${period}`}>
                  <Trans
                    i18nKey={`${Translate.common.WIDGET}:tableSpotPriceHistory.${period}.${
                      amount > 1 ? T_PLURAL : T_SINGULAR
                    }`}
                    values={{ value: amount }}
                  />
                </StyledTH>
              ))}
            </StyledTR>
          </StyledTHead>
          <StyledTBody>
            <StyledTR>
              {periodsData.map(({ value }, index) => (
                <StyledTD key={`${index}/${value}`}>
                  <Typography
                    color={value >= 0 ? EColor.SUCCESS : EColor.DANGER}
                    variant={ETypographyVariant.CAPTION2}
                  >
                    {value >= 0 ? '+' : ''}
                    {value}%
                  </Typography>
                </StyledTD>
              ))}
            </StyledTR>
          </StyledTBody>
        </StyledTable>
      </Box>
    </Box>
  );
};

const StyledTHead = styled.thead(({ theme: { palette } }: WithThemeProps) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);

  return css`
    height: 34px;
    background-color: ${getBackgroundColor({
      semanticType: EColor.SECONDARY,
      variant: EColor.LIGHT,
    })};

    ${StyledTH}:first-of-type {
      padding-left: 20px;
    }

    ${StyledTH}:last-of-type {
      padding-right: 20px;
    }

    ${StyledTH} + ${StyledTH} {
      padding-left: 8px;
    }
  `;
});

const StyledTBody = styled.tbody(({ theme: { palette } }: WithThemeProps) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);

  return css`
    ${StyledTR}:nth-child(even) {
      background-color: ${getBackgroundColor(EColor.INFO)};
    }
  `;
});

const StyledTH = styled.th(
  ({
    theme: {
      typography: { fontWeight },
    },
  }: WithThemeProps) =>
    ({
      fontWeight: fontWeight[EFontWeight.BOLD],
      minWidth: '66px',
    }) as CSSObject,
);

const StyledTR = styled.tr(({ theme: { palette } }: WithThemeProps) => {
  const { getColor } = getPaletteHandlers(palette);

  return css`
    border-bottom: 1px solid ${getColor({ commonType: EColor.GRAY, intensity: EColor.A200 })};
  `;
});

const StyledTD = styled.td`
  min-width: 66px;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;

  ${StyledTH}:first-child,
  ${StyledTD}:first-child {
    padding-left: 20px;
  }

  ${StyledTH}:last-child,
  ${StyledTD}:last-child {
    padding-right: 20px;
  }

  ${StyledTH},
  ${StyledTD} ${Typography.toString()} {
    white-space: nowrap;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 34px;
    font-size: 14px;
  }

  ${StyledTH} + ${StyledTH},
  ${StyledTD} + ${StyledTD} {
    padding-left: 8px;
  }
`;

export const getWidgetTableSpotPriceHistory = (section: SanityWidget) => {
  const props = section as WidgetTableSpotPriceHistoryComponentEntity;

  return (
    <WidgetTableSpotPriceHistory
      metalIso={props?.apiParams?.metalIso as EMetal}
      timeRanges={props?.timeRanges}
    />
  );
};
