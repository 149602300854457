import useTranslation from 'next-translate/useTranslation';

import { Button, Modal, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import {
  EActionAttribute,
  EButtonVariant,
  EFontWeight,
  ESize,
  ETypographyVariant,
} from '@core/type';
import { InvestmentChartsResponse } from '@core/type/api';
import { formatCurrency } from '@core/util';

import { ModalBaseProps } from '../../modal/interface-modal';

export const ModalInvested5YearsAgo = ({
  isOpen,
  onCloseModal,
  ...props
}: ModalBaseProps & InvestmentChartsResponse) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  const { t: tWidget } = useTranslation(Translate.common.WIDGET);
  const { t: tButton } = useTranslation(Translate.common.BUTTON);

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} size={ESize.LG}>
      <Modal.Header>
        <Typography variant={ETypographyVariant.H4} textAlign={'center'}>
          {tWidget('ifIHadInvested.howDoesItWork')}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography marginBottom={'32px'}>{tWidget('ifIHadInvested.weTakeThePrice')}</Typography>
        <Typography marginBottom={'32px'} fontWeight={EFontWeight.BOLD}>
          {tWidget('ifIHadInvested.yourTotalSavings')}
        </Typography>
        <Typography>
          {tWidget('ifIHadInvested.startingInvestment')}{' '}
          <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD}>
            {formatCurrency(language, currency, props.initialInvestment)}
          </Typography>
        </Typography>
        <Typography>
          {tWidget('ifIHadInvested.totalMonthlyInvestment')}{' '}
          <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD}>
            {formatCurrency(language, currency, props.recurringInvestmentTotal)}
          </Typography>
        </Typography>
        <Typography>
          {tWidget('ifIHadInvested.totalProfit')}{' '}
          <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD}>
            {formatCurrency(language, currency, props.profitTotal)}
            {` (${props.growth > 0 ? '+' : ''}${props.growth}%)`}
          </Typography>
        </Typography>
        <Typography>
          {tWidget('ifIHadInvested.totalSavings')}{' '}
          <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD}>
            {formatCurrency(language, currency, props.portfolioTotal)}
          </Typography>
        </Typography>
        <Typography marginTop={'48px'} fontStyle={'italic'}>
          {tWidget('ifIHadInvested.pastPerformance')}
        </Typography>
      </Modal.Body>

      <Modal.Footer display={'flex'} justifyContent={'center'}>
        <Button variant={EButtonVariant.OUTLINED} onClick={onCloseModal}>
          {tButton('close.title')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
