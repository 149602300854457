import { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Box } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, EColor, EFontWeight, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

export const Blockquote = ({ children }: { children: ReactNode }) => {
  const { isPhone } = useContextMediaQuery();

  return (
    <StyledBlockquote as={EActionAttribute.BLOCKQUOTE} isPhone={isPhone}>
      {children}
    </StyledBlockquote>
  );
};

export const StyledBlockquote = styled(Box)(({
  isPhone,
  theme: {
    palette,
    typography: { fontWeight },
  },
}: WithThemeProps & { isPhone: boolean }) => {
  const { getColor } = getPaletteHandlers(palette);

  return {
    padding: isPhone ? '0 0 0 24px' : '0 0 0 48px',
    fontWeight: fontWeight[EFontWeight.REGULAR],
    fontStyle: 'italic',
    borderLeft: `solid 1.2px ${getColor(EColor.SECONDARY)}`,
    margin: isPhone ? '72px 0' : '72px 64px',
    fontSize: isPhone ? '20px' : '24px',
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    lineHeight: isPhone ? 1.4 : 1.33,
  } as CSSObject;
});
