import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { PostLeadMagnetEntity } from '@core/type/api';

import { LeadMagnetAPI } from '../api/lead-magnet';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('LeadMagnetService', methodName, dto, apiCall, withContent);
};

const postLeadMagnet = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PostLeadMagnetEntity,
): Promise<void> => {
  return log('postLeadMagnet', { axiosInstance, language, ...data }, LeadMagnetAPI.postLeadMagnet);
};

export class LeadMagnetService {
  public static readonly postLeadMagnet = postLeadMagnet;
}
