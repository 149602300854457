import NextLink from 'next/link';

import { Box, Container, Typography } from '@core/component';
import { EActionAttribute, EPosition, EZIndexName, StyleRuleTextAlign } from '@core/type';
import { SanityImage } from '@core/type/sanity';

import { ImageBlock } from '../../../view/home/components/ImageBlock';

const getTextAlignFormPosition = (position: EPosition): StyleRuleTextAlign => {
  switch (position) {
    case EPosition.LEFT:
      return 'left';

    case EPosition.CENTER:
      return 'center';

    case EPosition.RIGHT:
      return 'right';
  }

  return 'center';
};

export const getImageBlockComponent = (section: SanityImage) => {
  const formattedSectionAlign = section.align as EPosition;

  const marginLeft =
    (formattedSectionAlign === EPosition.RIGHT || formattedSectionAlign === EPosition.CENTER) &&
    'auto';

  const marginRight =
    (formattedSectionAlign === EPosition.LEFT || formattedSectionAlign === EPosition.CENTER) &&
    'auto';

  return (
    <>
      {section?.image?.asset && (
        <Container position={'relative'} zIndex={EZIndexName.OVER_DEFAULT}>
          <Box as={EActionAttribute.FIGURE}>
            <Box
              width={section.width}
              position="relative"
              marginLeft={marginLeft}
              marginRight={marginRight}
            >
              {section?.href ? (
                <NextLink href={section?.href} target={section?.targetBlank ? '_blank' : undefined}>
                  <ImageBlock alt={section.alt} title={section?.title} {...section.image.asset} />
                </NextLink>
              ) : (
                <ImageBlock alt={section.alt} title={section?.title} {...section.image.asset} />
              )}
            </Box>
            {section?.caption && (
              <Typography
                as={EActionAttribute.FIGCAPTION}
                textAlign={getTextAlignFormPosition(formattedSectionAlign)}
              >
                {section?.caption}
              </Typography>
            )}
          </Box>
        </Container>
      )}
    </>
  );
};
