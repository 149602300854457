/* eslint-disable @typescript-eslint/no-magic-numbers */
import Trans from 'next-translate/Trans';
import { ReactNode, useRef, useState } from 'react';

import { Box, Line, Paper, StyledSelectBaseIcon, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EColor, EMetal, ESize, ETypographyVariant, EZIndexName } from '@core/type';

import { HomepageSelectOptions } from '../interface-home';

const CloseArea = ({
  isFocus,
  setIsFocus,
}: {
  isFocus: boolean;
  setIsFocus: (b: boolean) => void;
}) => {
  if (!isFocus) {
    return null;
  }

  return (
    <Box
      position={'fixed'}
      top={0}
      left={0}
      width={'100vw'}
      height={'100vh'}
      zIndex={EZIndexName.DROPDOWN}
      onClick={() => setIsFocus(false)}
    />
  );
};

const SelectTypography = ({ children, isMobile }: { children: ReactNode; isMobile: boolean }) => (
  <Typography lineHeight={isMobile ? '54px' : '66px'} variant={ETypographyVariant.DISPLAY}>
    {children}
  </Typography>
);

const options: HomepageSelectOptions[] = [
  {
    label: <Trans i18nKey={`${Translate.common.PRODUCT}:metalTypes.gold`} />,
    value: EMetal.GOLD,
  },
  {
    label: <Trans i18nKey={`${Translate.common.PRODUCT}:metalTypes.silver`} />,
    value: EMetal.SILVER,
  },
];

export const HomepageSelect = ({
  selectMetal,
  setSelectMetal,
}: {
  selectMetal: EMetal;
  setSelectMetal: (metal: EMetal.GOLD | EMetal.SILVER) => void;
}) => {
  const { isMobile } = useContextMediaQuery();

  const [isFocus, setIsFocus] = useState(false);

  const blockRef = useRef<HTMLDivElement>(null);

  const optionTitle = () => options.find(({ value }) => value !== selectMetal);

  const borderRadius = isMobile ? '8px' : '16px';
  const height = isMobile ? 56 : 68;

  return (
    <Box ref={blockRef} position={'relative'} height={`${height}px`}>
      <CloseArea isFocus={isFocus} setIsFocus={setIsFocus} />

      <Paper
        borderRadius={borderRadius}
        position={'absolute'}
        zIndex={isFocus ? EZIndexName.DROPDOWN : undefined}
        boxShadow={isFocus ? EColor.ELEVATION_2 : undefined}
        onClick={() => setIsFocus(!isFocus)}
        borderColor={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
        borderWidth={'1px'}
        cursor={'pointer'}
        height={isFocus ? `${height * 2}px` : `${height}px`}
        overflowY={'hidden'}
        hoverStyles={{ boxShadow: !isFocus && EColor.ELEVATION_2 }}
      >
        <Box
          display={'flex'}
          gap={isMobile ? '4px' : '16px'}
          alignItems={'center'}
          paddingLeft={'24px'}
          color={EColor.ACCENT}
          hoverStyles={{
            color: { semanticType: EColor.SECONDARY, variant: EColor.DARK },
          }}
          borderRadius={borderRadius}
          transition={'0.3s'}
        >
          <SelectTypography isMobile={isMobile}>
            {selectMetal === EMetal.GOLD ? (
              <Trans i18nKey={`${Translate.common.PRODUCT}:metalTypes.gold`} />
            ) : (
              <Trans i18nKey={`${Translate.common.PRODUCT}:metalTypes.silver`} />
            )}
          </SelectTypography>

          <StyledSelectBaseIcon
            isOpen={isFocus}
            size={isMobile ? ESize.XL : ESize.XXL}
            color={EColor.ACCENT}
            width={'86px'}
          />
        </Box>

        <Line isFullWidth />

        <Box
          hoverStyles={{ color: { semanticType: EColor.SECONDARY, variant: EColor.DARK } }}
          paddingLeft={'24px'}
          transition={'0.3s'}
          onClick={() => setSelectMetal(optionTitle().value)}
        >
          <SelectTypography isMobile={isMobile}>{optionTitle().label}</SelectTypography>
        </Box>
      </Paper>
    </Box>
  );
};
