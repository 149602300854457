import { EUnit } from '@core/type';
import { ETableSpotWeights } from '@core/type/api';

export const tableSpotWeights: Record<
  ETableSpotWeights,
  { coef: number; ref: EUnit; title: string }
> = {
  [ETableSpotWeights.OUNCE]: { coef: 1, ref: EUnit.OUNCE, title: 'Ounce' },
  [ETableSpotWeights.OUNCE5]: { coef: 5, ref: EUnit.OUNCE, title: '5 Ounces' },
  [ETableSpotWeights.OUNCE10]: { coef: 10, ref: EUnit.OUNCE, title: '10 Ounces' },
  [ETableSpotWeights.OUNCE100]: { coef: 100, ref: EUnit.OUNCE, title: '100 Ounces' },
  [ETableSpotWeights.GRAM]: { coef: 1, ref: EUnit.GRAM, title: 'Gram' },
  [ETableSpotWeights.GRAM5]: { coef: 5, ref: EUnit.GRAM, title: '5 Grams' },
  [ETableSpotWeights.GRAM10]: { coef: 10, ref: EUnit.GRAM, title: '10 Grams' },
  [ETableSpotWeights.GRAM100]: { coef: 100, ref: EUnit.GRAM, title: '100 Grams' },
  [ETableSpotWeights.KILO]: { coef: 1, ref: EUnit.KILOGRAM, title: 'Kg' },
  [ETableSpotWeights.KILO5]: { coef: 5, ref: EUnit.KILOGRAM, title: '5 Kg' },
  [ETableSpotWeights.KILO10]: { coef: 10, ref: EUnit.KILOGRAM, title: '10 Kg' },
  [ETableSpotWeights.KILO100]: { coef: 100, ref: EUnit.KILOGRAM, title: '100 Kg' },
  [ETableSpotWeights.TON]: { coef: 1, ref: EUnit.TONNE, title: 'Ton' },
};
