import { useMemo } from 'react';

import { Container, TabPanel } from '@core/component';
import { TabPanelProps } from '@core/component/interface';
import { useContextMediaQuery } from '@core/context';
import { EColor } from '@core/type';

export const TabPanelProductDetails = ({ children, ...restProps }: TabPanelProps<number>) => {
  const { isPhone, isMobile } = useContextMediaQuery();

  const padding = useMemo(() => {
    if (isPhone) {
      return '20px';
    }

    if (isMobile) {
      return '24px';
    }

    return '40px';
  }, [isPhone, isMobile]);

  return (
    <TabPanel<number>
      margin={isPhone ? '0 -20px' : '0px'}
      padding={padding}
      backgroundColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
      hasElevation={false}
      {...restProps}
    >
      <Container gutter={'0'} maxWidth={'754px'}>
        {children}
      </Container>
    </TabPanel>
  );
};
