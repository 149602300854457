import type { LogLevelDesc } from 'loglevel';

import { EDevice } from './util';

export enum EVariableNameEnvironmentPublic {
  API_HOST = 'apiHost',
  WS_HOST = 'wsHost',
  API_BASE = 'apiBase',
  API_VERSION = 'apiVersion',
  ENABLE_BEFORE_UNLOAD = 'enableBeforeUnload',
  LOGGER = 'logger',
  SAFERPAY_URL = 'saferpayUrl',
  ENABLE_GOOGLE_ANALYTICS = 'enableGoogleAnalytics',
  ENABLE_MIXPANEL = 'enableMixpanel',
  GOOGLE_ANALYTICS_ID = 'googleAnalyticsId',
  FACEBOOK_PIXEL_ID = 'facebookPixelId',
  MIXPANEL_ID = 'mixpanelId',
  CRISP_WEBSITE_ID = 'crispWebsiteId',
  CDN_CSP = 'cdnCsp',
  ENABLE_CRISP = 'enableCrisp',
  ENABLE_TRUSTPILOT = 'enableTrustpilot',
  TRUSTPILOT_API_KEY = 'trustpilotApiKey',
  ENABLE_SENTRY = 'enableSentry',
  ENABLE_WEB_SOCKET = 'enableWebSocket',
  ENABLE_ANALYTICS = 'enableAnalytics',
  ENABLE_VERCEL_PREVIEW_FEEDBACK = 'enableVercelPreviewFeedback',
  ENABLE_WEB_VITALS = 'enableWebVitals',
  ENV = 'env',
  SENTRY_ENVELOPE = 'sentryEnvelope',
  SENTRY_SECURITY = 'sentrySecurity',
  CDN_API = 'cdnApi',
  ENABLE_CDN = 'enableCdn',
  ENABLE_YOUTUBE = 'enableYoutube',
  STALE_TIME_CLIENT = 'staleTimeClient',
  ENABLE_RECAPTCHA = 'enableRecaptcha',
  RECAPTCHA_KEY = 'recaptchaKey',
  GOOGLE_API_KEY = 'googleApiKey',
  CDN_IMAGE = 'cdnImage',
  CDN_IMAGE_FOLDER = 'cdnImageFolder',
  TOKEN_FAKE_USER = 'tokenFakeUser',
  MAINTENANCE_MODE = 'maintenanceMode',
  AXEPTIO_CLIENT_ID = 'axeptioClientId',
  SANITY_PROJECT_ID = 'sanityId',
  SANITY_DATASET = 'sanityDataset',
  SANITY_API_VERSION = 'sanityVersion',
  SANITY_TOKEN = 'sanityToken',
  SANITY_ENABLE_CDN = 'enableSanityCdn',
  ENABLE_MOBILE_APP_PROMOTION_BANNER = 'enableMobileAppPromotionBanner',
  ENABLE_NEW_HEADER = 'enableNewHeader',
}

enum EPublic {
  API_HOST = 'apiHost',
  WS_HOST = 'wsHost',
  API_BASE = 'apiBase',
  API_VERSION = 'apiVersion',
  ENABLE_BEFORE_UNLOAD = 'enableBeforeUnload',
  LOGGER = 'logger',
  SAFERPAY_URL = 'saferpayUrl',
  ENABLE_GOOGLE_ANALYTICS = 'enableGoogleAnalytics',
  ENABLE_MIXPANEL = 'enableMixpanel',
  GOOGLE_ANALYTICS_ID = 'googleAnalyticsId',
  FACEBOOK_PIXEL_ID = 'facebookPixelId',
  MIXPANEL_ID = 'mixpanelId',
  CRISP_WEBSITE_ID = 'crispWebsiteId',
  CDN_CSP = 'cdnCsp',
  ENABLE_CRISP = 'enableCrisp',
  ENABLE_TRUSTPILOT = 'enableTrustpilot',
  ENABLE_SENTRY = 'enableSentry',
  ENABLE_WEB_SOCKET = 'enableWebSocket',
  ENABLE_ANALYTICS = 'enableAnalytics',
  ENABLE_VERCEL_PREVIEW_FEEDBACK = 'enableVercelPreviewFeedback',
  ENABLE_WEB_VITALS = 'enableWebVitals',
  ENV = 'env',
  SENTRY_ENVELOPE = 'sentryEnvelope',
  SENTRY_SECURITY = 'sentrySecurity',
  CDN_API = 'cdnApi',
  ENABLE_CDN = 'enableCdn',
  ENABLE_YOUTUBE = 'enableYoutube',
  STALE_TIME_CLIENT = 'staleTimeClient',
  ENABLE_RECAPTCHA = 'enableRecaptcha',
  RECAPTCHA_KEY = 'recaptchaKey',
  GOOGLE_API_KEY = 'googleApiKey',
  CDN_IMAGE = 'cdnImage',
  CDN_IMAGE_FOLDER = 'cdnImageFolder',
  TOKEN_FAKE_USER = 'tokenFakeUser',
  MAINTENANCE_MODE = 'maintenanceMode',
  AXEPTIO_CLIENT_ID = 'axeptioClientId',
  SANITY_PROJECT_ID = 'sanityId',
  SANITY_DATASET = 'sanityDataset',
  SANITY_API_VERSION = 'sanityVersion',
  SANITY_TOKEN = 'sanityToken',
  SANITY_ENABLE_CDN = 'enableSanityCdn',
  TRUSTPILOT_API_KEY = 'trustpilotApiKey',
  ENABLE_MOBILE_APP_PROMOTION_BANNER = 'enableMobileAppPromotionBanner',
  ENABLE_NEW_HEADER = 'enableNewHeader',
}

export type EnvironmentPublic = {
  [EPublic.API_HOST]: string;
  [EPublic.WS_HOST]: string;
  [EPublic.API_BASE]: string;
  [EPublic.API_VERSION]: string;
  [EPublic.ENABLE_BEFORE_UNLOAD]: boolean;
  [EPublic.ENABLE_ANALYTICS]: boolean;
  [EPublic.LOGGER]: LogLevelDesc;
  [EPublic.ENABLE_GOOGLE_ANALYTICS]: boolean;
  [EPublic.ENABLE_MIXPANEL]: boolean;
  [EPublic.GOOGLE_ANALYTICS_ID]: string;
  [EPublic.FACEBOOK_PIXEL_ID]: string;
  [EPublic.MIXPANEL_ID]: string;
  [EPublic.CRISP_WEBSITE_ID]: string;
  [EPublic.CDN_CSP]: string;
  [EPublic.ENABLE_CRISP]: boolean;
  [EPublic.ENABLE_TRUSTPILOT]: boolean;
  [EPublic.ENABLE_SENTRY]: boolean;
  [EPublic.ENABLE_WEB_SOCKET]: boolean;
  [EPublic.ENABLE_VERCEL_PREVIEW_FEEDBACK]: boolean;
  [EPublic.ENABLE_WEB_VITALS]: boolean;
  [EPublic.ENV]: 'production' | 'staging' | 'development' | 'preview' | 'local';
  [EPublic.SENTRY_ENVELOPE]: string;
  [EPublic.SENTRY_SECURITY]: string;
  [EPublic.CDN_API]: string;
  [EPublic.ENABLE_CDN]: boolean;
  [EPublic.ENABLE_YOUTUBE]: boolean;
  [EPublic.STALE_TIME_CLIENT]: number;
  [EPublic.ENABLE_RECAPTCHA]: boolean;
  [EPublic.MAINTENANCE_MODE]: boolean;
  [EPublic.RECAPTCHA_KEY]: string;
  [EPublic.GOOGLE_API_KEY]: string;
  [EPublic.CDN_IMAGE]: string;
  [EPublic.CDN_IMAGE_FOLDER]: string;
  [EPublic.TOKEN_FAKE_USER]: string;
  [EPublic.SAFERPAY_URL]: string;
  [EPublic.MAINTENANCE_MODE]: boolean;
  [EPublic.AXEPTIO_CLIENT_ID]: string;
  [EPublic.SANITY_API_VERSION]: string;
  [EPublic.SANITY_DATASET]: string;
  [EPublic.SANITY_PROJECT_ID]: string;
  [EPublic.SANITY_TOKEN]: string;
  [EPublic.SANITY_ENABLE_CDN]: boolean;
  [EPublic.TRUSTPILOT_API_KEY]: string;
  [EPublic.ENABLE_MOBILE_APP_PROMOTION_BANNER]: boolean;
  [EPublic.ENABLE_NEW_HEADER]: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EnvironmentPublicApp<T> = EnvironmentPublic & T;

export enum ETypeApp {
  NEXT = 'next',
  REACT_NATIVE = 'reactNative',
}

export enum EVariableNameEnvironmentPrivate {
  PUBLIC_KEY = 'publicKey',
  STALE_TIME_SERVER = 'staleTimeServer',
  CACHE_TIME_SERVER = 'cacheTimeServer',
  IPDATA_KEY = 'ipdataKey',
  SAFERPAY_TERMINAL_ID = 'saferpayTerminalId',
  TOKEN_AUTHORIZATION_API = 'tokenAuthorizationApi',
  DEVICE_TYPE = 'deviceType',
  SAFERPAY_CUSTOMER_ID = 'saferpayCustomerId',
  SAFERPAY_TOKEN = 'saferpayToken',
}

enum EPrivate {
  PUBLIC_KEY = 'publicKey',
  STALE_TIME_SERVER = 'staleTimeServer',
  CACHE_TIME_SERVER = 'cacheTimeServer',
  IPDATA_KEY = 'ipdataKey',
  SAFERPAY_TERMINAL_ID = 'saferpayTerminalId',
  TOKEN_AUTHORIZATION_API = 'tokenAuthorizationApi',
  DEVICE_TYPE = 'deviceType',
  SAFERPAY_CUSTOMER_ID = 'saferpayCustomerId',
  SAFERPAY_TOKEN = 'saferpayToken',
}

export type EnvironmentPrivate = {
  [EPrivate.PUBLIC_KEY]: string;
  [EPrivate.STALE_TIME_SERVER]: number;
  [EPrivate.CACHE_TIME_SERVER]: number;
  [EPrivate.IPDATA_KEY]: string;
  [EPrivate.SAFERPAY_TERMINAL_ID]: string;
  [EPrivate.TOKEN_AUTHORIZATION_API]: string;
  [EPrivate.DEVICE_TYPE]: EDevice;
  [EPrivate.SAFERPAY_CUSTOMER_ID]: string;
  [EPrivate.SAFERPAY_TOKEN]: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EnvironmentPrivateApp<T> = EnvironmentPrivate & T;
