import { Datetime, ECurrency, EMetal } from '../interface';

export enum ETablePeriod {
  ONE_HOUR = 'oneHour',
  SIX_HOURS = 'sixHours',
  TWELVE_HOURS = 'twelveHours',
  SIXTEEN_HOURS = 'sixteenHours',
  TWENTY_HOURS = 'twentyHours',
  ONE_DAY = 'oneDay',
  ONE_WEEK = 'oneWeek',
  ONE_MONTH = 'oneMonth',
  SIX_MONTHS = 'sixMonths',
  ONE_YEAR = 'oneYear',
  FIVE_YEARS = 'fiveYears',
  TEN_YEARS = 'tenYears',
  TWENTY_YEARS = 'twentyYears',
  FIFTY_YEARS = 'fiftyYears',
}

export type SpotPriceHistoryRequest = {
  metalIso: EMetal;
  currencyIso: ECurrency;
};

export type SpotPriceHistoryResponse = {
  createdAt: Datetime;
  metalIso: EMetal;
  currencyIso: ECurrency;
  periods: Partial<Record<ETablePeriod, number>>;
};
