import Script from 'next/script';

export const TrustpilotNextScript = ({
  nonce,
  url,
}: {
  nonce: string;
  url: string;
}): JSX.Element | null => {
  return nonce ? (
    <Script
      strategy="lazyOnload"
      src={`${url}/bootstrap/v5/tp.widget.bootstrap.min.js`}
      defer
      nonce={nonce}
    />
  ) : null;
};
