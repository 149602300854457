import { createContext } from 'react';

import type { HookStore, MetalIsoHook, MetalIsoState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const metalIsoContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, MetalIsoState, MetalIsoHook>>(null);

export const useContextMetalIso = () => {
  return useFnContext(metalIsoContext);
};

export function useStoreMetalIso<Selector>(selector: keyof MetalIsoState) {
  return useStoreContext<Selector, MetalIsoState, MetalIsoHook>(selector, metalIsoContext);
}
