import { Translate } from 'next-translate';

import { tableSpotWeights } from '@core/constant';
import { ECurrency, ELanguageTags } from '@core/type';
import {
  ETableSpotPriceCarat,
  ETableSpotPriceCategory,
  ETableSpotPriceCurrency,
  ETableSpotWeights,
  FormattedTableSpotPriceRow,
  SpotPriceWeightsResponse,
  TableSpotPriceRow,
} from '@core/type/api';
import { formatCurrency } from '@core/util';

const ROUNDING = 2;

export const formatRowTranslationByKey = (
  key: ETableSpotWeights | ETableSpotPriceCarat | ETableSpotPriceCurrency,
  tab: ETableSpotPriceCategory,
  t: Translate,
) => {
  switch (tab) {
    case ETableSpotPriceCategory.CARATS:
      return key;
    case ETableSpotPriceCategory.CURRENCIES:
      return (key as string).toUpperCase();
    case ETableSpotPriceCategory.WEIGHT:
      return t(`tableMetalPrice.${key}`);
  }
};

export const formatCaratsLinks = <T = string>(links: Record<ETableSpotPriceCarat, T>) =>
  (Object.keys(links) as unknown as ETableSpotPriceCarat[]).reduce(
    (acc, key) => ({
      ...acc,
      [ETableSpotPriceCarat[key]]: links[key],
    }),
    {} as Record<ETableSpotPriceCarat, T>,
  );

const formatRowData = (
  language: ELanguageTags,
  currency: ECurrency,
  rowData: TableSpotPriceRow,
): FormattedTableSpotPriceRow => ({
  currentPrice: formatCurrency(language, currency, rowData.currentPrice),
  dayMin: formatCurrency(language, currency, rowData.dayMin),
  dayMax: formatCurrency(language, currency, rowData.dayMax),
  dayAverage: formatCurrency(language, currency, rowData.dayAverage),
  dayPerformance: rowData.dayPerformance,
});

export const formatTableData = <T extends { table: Record<string, TableSpotPriceRow> }, K>(
  data: T,
  language: ELanguageTags,
): { key: K; rowData: FormattedTableSpotPriceRow }[] => {
  return Object.entries(data?.table)
    .filter(([, rowData]) => rowData.currentPrice !== null)
    .map(([key, rowData]) => {
      const currency = key as ECurrency;

      const formattedRowData = formatRowData(language, currency, rowData);

      return {
        key: key as K,
        rowData: formattedRowData,
      };
    });
};

export const formatCaratTableData = <T extends { table: Record<string, TableSpotPriceRow> }, K>(
  data: T,
  language: ELanguageTags,
  currency: ECurrency,
): { key: K; rowData: FormattedTableSpotPriceRow }[] => {
  return Object.entries(data?.table)
    .filter(([, rowData]) => rowData.currentPrice !== null)
    .map(([key, rowData]) => {
      const formattedRowData = formatRowData(language, currency, rowData);

      return {
        key: key as K,
        rowData: formattedRowData,
      };
    });
};

export const formatWeightTableData = (
  data: SpotPriceWeightsResponse,
  weights: Record<ETableSpotWeights, boolean>,
  language: ELanguageTags,
  currency: ECurrency,
): { key: ETableSpotWeights; rowData: FormattedTableSpotPriceRow }[] => {
  return (Object.keys(weights) as ETableSpotWeights[]).flatMap((key) => {
    if (weights[key]) {
      const rowData = multiplyTableData(
        data.table[tableSpotWeights[key].ref as keyof typeof data.table],
        tableSpotWeights[key].coef,
      );

      if (rowData.currentPrice === null) {
        return [];
      }

      const formattedRowData = formatRowData(language, currency, rowData);

      return [{ key, rowData: formattedRowData }];
    }

    return [];
  });
};

const multiplyTableData = (data: TableSpotPriceRow, coef: number): TableSpotPriceRow => ({
  ...data,
  currentPrice: Number((data.currentPrice * coef).toFixed(ROUNDING)),
  dayMin: Number((data.dayMin * coef).toFixed(ROUNDING)),
  dayMax: Number((data.dayMax * coef).toFixed(ROUNDING)),
  dayAverage: Number((data.dayAverage * coef).toFixed(ROUNDING)),
});
