import { useContextGR } from '@google-recaptcha';
import { useEventEmailCaptured } from '@google-tag-manager';
import withTranslation from 'next-translate/withTranslation';

import { SubscribeService } from '@core/api';
import {
  Box,
  ButtonSubmit,
  FormControl,
  Image,
  InputTextControl,
  Loader,
  spotlightBannerCollapsingAnimation,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser, useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { getStaticPathIconFolder } from '@core/media';
import { EColor, EGTMCaptureLocation, ETypographyVariant, WithTranslation } from '@core/type';

import { SubmitSuccessful } from './SubmitSuccessful';
import { ANIMATION_TIME } from './constants';
import { FormProps, InputForm } from './interface-spotlight-banner';
import { useSpotlightForm } from './use-utils-form';

export const SecondaryBannerSpotlightBase = ({
  i18n: { t },
  onSuccessfulAnimationEnd,
}: WithTranslation & FormProps) => {
  const { getRecaptcha } = useContextGR();
  const { axiosInstance } = useContextUtil();
  const { language } = useLanguage();
  const { pushEventEmailCaptured } = useEventEmailCaptured();

  const { email: emailUser } = useContextUser();

  const {
    isClosed,
    setIsClosed,
    isClosing,
    setIsClosing,
    control,
    handleSubmit,
    trigger,
    reset,
    setError,
    isSubmitSuccessful,
    tForm,
    isSubmitting,
    onSuccess,
  } = useSpotlightForm();

  if (isClosed) {
    return null;
  }

  return (
    <Box
      position="relative"
      backgroundColor={EColor.INFO}
      padding="40px"
      animation={isClosing ? spotlightBannerCollapsingAnimation : undefined}
      animationDuration={`${ANIMATION_TIME}ms`}
      borderRadius="16px"
    >
      <FormControl
        t={tForm}
        trigger={trigger}
        reset={reset}
        handleSubmit={handleSubmit}
        setError={setError}
        fn={async (data: InputForm) => {
          const token = await getRecaptcha();
          const submitData = {
            ...data,
            token,
          };
          return SubscribeService.subscribeEmail(axiosInstance, language, submitData).then(
            (data) => {
              pushEventEmailCaptured(EGTMCaptureLocation.NEWSLETTER);
              return data;
            },
          );
        }}
        onSuccess={onSuccess}
      >
        <Box display="flex" justifyContent="center">
          <Image
            src={getStaticPathIconFolder('spotlight/ic-subscribe.svg')}
            width="265"
            height="65"
            alt="image-letter"
          />
        </Box>
        {isSubmitSuccessful ? (
          <SubmitSuccessful
            setIsClosed={setIsClosed}
            setIsClosing={setIsClosing}
            onSuccessfulAnimationEnd={onSuccessfulAnimationEnd}
          />
        ) : (
          <>
            <Box position="relative" marginTop="24px" marginBottom="12px">
              <Typography fontStyle="italic" variant={ETypographyVariant.H3} textAlign="center">
                {t('theSpotlight')}
              </Typography>
            </Box>
            <Typography variant={ETypographyVariant.BODY2} textAlign="center" marginBottom="24px">
              {t('subscribe.description')}
            </Typography>

            {isSubmitting ? (
              <Box display="flex" justifyContent="center">
                <Loader />
              </Box>
            ) : (
              <Box margin="0 auto">
                {emailUser ? (
                  <Box display="flex" justifyContent="center">
                    <ButtonSubmit>{t('subscribe.buttonSubscribe')}</ButtonSubmit>
                  </Box>
                ) : (
                  <>
                    <InputTextControl
                      control={control}
                      name="email"
                      label={tForm('email.placeholder')}
                    />

                    <Box display="flex" justifyContent="center">
                      <ButtonSubmit marginTop="32px" marginLeft="auto" marginRight="auto">
                        {t('subscribe.buttonSubscribe')}
                      </ButtonSubmit>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </FormControl>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SecondaryBannerSpotlight = withTranslation(
  SecondaryBannerSpotlightBase,
  Translate.common.PRODUCT,
);
