import { useContextGR } from '@google-recaptcha';
import { useEventEmailCaptured } from '@google-tag-manager';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';

import { LeadMagnetService } from '@core/api';
import { Box, Button, FormControl, InputTextControl, Loader, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextUtil } from '@core/context';
import { useLanguage, useValidator } from '@core/hook';
import {
  EColor,
  EGTMCaptureLocation,
  ERouting,
  ETypographyVariant,
  LeadMagnetForm,
  leadMagnetValidator,
} from '@core/type';
import { ELeadMagnetPage, ELeadMagnetPageSlug } from '@core/type/api';

import { FormProps } from './interface-lead-magnet';

const getSlugFromRequestPage = (requestPage: ELeadMagnetPage): ELeadMagnetPageSlug => {
  switch (requestPage) {
    case ERouting.HOME:
      return 'homepage';
    case ERouting.BLOG_HOME:
      return 'blog_page';
    case ERouting.CHARTS:
      return 'chart_pages';
  }
};

export const Form = ({ buttonLabel, requestPage }: FormProps) => {
  const { t: tForm } = useTranslation(Translate.common.FORM);
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);
  const { pushEventEmailCaptured } = useEventEmailCaptured();

  const { handleSubmit, control, trigger, reset, setError, formState } = useForm({
    mode: 'onChange',
    resolver: useValidator(leadMagnetValidator, tForm),
    defaultValues: {
      email: '',
    },
  });

  const { getRecaptcha } = useContextGR();
  const { axiosInstance } = useContextUtil();
  const { language } = useLanguage();

  const { isSubmitting } = formState;

  return (
    <FormControl
      t={tForm}
      trigger={trigger}
      reset={reset}
      handleSubmit={handleSubmit}
      setError={setError}
      onSuccess={reset}
      fn={async (data: LeadMagnetForm) => {
        const recaptchaToken = await getRecaptcha();

        return LeadMagnetService.postLeadMagnet(axiosInstance, language, {
          ...data,
          recaptchaToken,
          requestPage: getSlugFromRequestPage(requestPage),
        }).then((data) => {
          pushEventEmailCaptured(EGTMCaptureLocation.LEAD_MAGNET);
          return data;
        });
      }}
    >
      {isSubmitting ? (
        <Box marginTop="30px" marginBottom="30px">
          <Loader />
        </Box>
      ) : (
        <>
          <InputTextControl control={control} name="email" label={tForm('email.placeholder')} />
          <Box maxWidth="362px" marginTop="14px" marginBottom="14px">
            <Button color={EColor.SECONDARY} isFullWidth type="submit">
              {buttonLabel}
            </Button>
          </Box>
          <Typography
            variant={ETypographyVariant.CAPTION2}
            textAlign="center"
            color={EColor.SECONDARY}
          >
            {tProduct('leadMagnet.subscriptionNotice')}
          </Typography>
        </>
      )}
    </FormControl>
  );
};
