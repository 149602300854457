import { Box } from '@core/component';
import { ESize, MarginProps, MarginSize, marginSize } from '@core/type';
import { SanityWidget } from '@core/type/sanity';

export const Margin = ({ size = ESize.XS }: MarginProps) => {
  return <Box marginBottom={marginSize[size]}></Box>;
};

export const getMarginComponent = (section: SanityWidget) => {
  const marginSection = section as SanityWidget & MarginProps;

  return <Margin size={marginSection?.size as MarginSize} />;
};
