import type { FC } from 'react';

import { TRUSTPILOT_STARS, trustpilotIds } from '@core/constant';
import { ELanguageTags } from '@core/type';
import { getLanguageFromTags } from '@core/util';

import { TrustpilotComponent } from './TrustpilotComponent';

export const getTrustpilotTag = (language: ELanguageTags, tag: string) =>
  `${tag}${getLanguageFromTags(language).toUpperCase()}`;

export type TrustpilotLongProps = {
  locale: ELanguageTags;
  height?: string;
  width?: string;
  theme?: 'light' | 'dark';
  tag: string;
  star?: string;
};

export const TrustpilotLong: FC<TrustpilotLongProps> = (
  props: TrustpilotLongProps,
): JSX.Element => (
  <TrustpilotComponent
    templateId={trustpilotIds.TEMPLATE_ID_LONG}
    businessunitId={trustpilotIds.BUSINESSUNIT_ID}
    height={'240px'}
    width={'100%'}
    theme={'light'}
    star={TRUSTPILOT_STARS}
    tag={getTrustpilotTag(props.locale, props.tag)}
    locale={props.locale}
  />
);
