import { Box, Line, Typography } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, EColor, EFontWeight, ETypographyVariant } from '@core/type';

export type SpecificationLineProps = {
  title: string;
  value: string | number;
  hasLine?: boolean;
};

export const LineSpecification = ({ title, value, hasLine = true }: SpecificationLineProps) => {
  const { isPhone } = useContextMediaQuery();

  return value ? (
    <>
      <Box display={'flex'} justifyContent={'space-between'} padding={'16px 0'}>
        <Typography
          variant={isPhone ? ETypographyVariant.CAPTION1 : ETypographyVariant.H6}
          fontWeight={EFontWeight.BOLD}
          as={EActionAttribute.H3}
        >
          {title}
        </Typography>
        <Typography variant={isPhone ? ETypographyVariant.CAPTION1 : ETypographyVariant.BODY2}>
          {value}
        </Typography>
      </Box>
      {hasLine &&
        (isPhone ? (
          <Line color={{ commonType: EColor.WHITE, intensity: EColor.R900 }} />
        ) : (
          <Line />
        ))}
    </>
  ) : null;
};
