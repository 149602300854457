import { ECurrency, ELiveChartsPeriod, MetalPrice, Price } from '@core/type';
import { BlogArticleEntity } from '@core/type/api';

export type ChipPeriod = {
  period: ELiveChartsPeriod;
  text: string;
};

export type LivePriceProps = { deskbid: number; metalPrice: Price; currency: ECurrency };

export type PriceModalProps = MetalPrice & {
  isOpen: boolean;
  onCloseModal: () => void;
  categoryTitleDowncase: string;
};

export type ArticleCardsProps = {
  blogArticles: BlogArticleEntity[];
  categoryTitleDowncase: string;
  category: string;
  secondPrefix: string;
};

export enum ESelectChartCategory {
  METAL = 'metal',
  WEIGHT_UNIT = 'weightUnit',
  CURRENCY = 'currency',
}

export type WidgetChartInvestmentProps = {
  metalValue: string;
  hasMonthlyInvestment: boolean;
  setMetalValue: (value: string) => void;
  setMonthlyInvestmentValue: (value: number) => void;
  setInitialInvestmentValue: (value: number) => void;
  setHasMonthlyInvestment: (value: boolean) => void;
};
