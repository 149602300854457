import {
  SanityAssetIdStub,
  SanityAssetPathStub,
  SanityAssetUrlStub,
  SanityImageAsset,
  SanityReference,
} from '@sanity/asset-utils';
import Image from 'next/image';

import { urlFor } from '@core/sanity/utils';

export const ImageBlock = ({
  alt,
  title,
  ...asset
}: (
  | SanityReference
  | SanityImageAsset
  | SanityAssetIdStub
  | SanityAssetPathStub
  | SanityAssetUrlStub
) & { alt: string; title?: string }) => {
  return (
    <Image
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      src={urlFor(asset).url()}
      alt={alt}
      fill
      style={{
        objectFit: 'contain',
        height: null,
        position: null,
      }}
      title={title}
    />
  );
};
