import { useEffect, useRef } from 'react';

import { WebSocketService } from '@core/api';
import { weightToShortenedSlug } from '@core/constant';
import { useStoreCurrency, useStoreLiveChartsPrice, useStoreUtil } from '@core/context';
import { useQueryApi } from '@core/hook';
import { ECurrency, ELiveChartsPeriod, EWebSocketIdentifier, LiveCharts } from '@core/type';
import { EQueryKey, LiveChartsRequest, LiveChartsResponse } from '@core/type/api';
import { HookStoreFn, LiveChartsPriceHook, LiveChartsPriceState } from '@core/type/context';

import { useMetalIso } from './use-metal-iso';
import { useWeightUnit } from './use-weight-unit';

export const makeUseLiveChartsPrice = (
  wsHost: string,
): HookStoreFn<LiveChartsPriceState, LiveChartsPriceHook> => {
  const useLiveChartsPrice: HookStoreFn<
    LiveChartsPriceState,
    LiveChartsPriceHook
  > = (): LiveChartsPriceHook => {
    const [currency] = useStoreCurrency<ECurrency>('currency');
    const [isBotDetected] = useStoreUtil<boolean>('isBotDetected');

    const { metalIso: metal } = useMetalIso();

    const { weightUnit } = useWeightUnit();

    const websocket = useRef<WebSocket>(null);

    const [liveChartsPrices, setLiveChartsPrices] =
      useStoreLiveChartsPrice<LiveCharts>('liveChartsPrices');

    const { data, isSuccess } = useQueryApi<LiveChartsResponse, LiveChartsRequest>(
      EQueryKey.LIVE_CHARTS,
      {
        metal,
        weightUnit,
        currency,
        period: ELiveChartsPeriod.DAY,
      },
      {
        enabled: !!currency && !!metal && !!weightUnit,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    );

    useEffect(() => {
      if (isSuccess) {
        setLiveChartsPrices({
          pricesList: data.pricesList,
          priceChanges: data.priceChanges,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, currency, metal, weightUnit]);

    useEffect(() => {
      if (!isBotDetected) {
        websocket.current = WebSocketService.open(wsHost, EWebSocketIdentifier.LIVE_CHART, {
          IsoCurrency: currency,
          IsoMetal: metal,
          WeightUnit: weightToShortenedSlug[weightUnit],
        });
        WebSocketService.message(
          websocket.current,
          EWebSocketIdentifier.LIVE_CHART,
          (state: LiveCharts) => {
            setLiveChartsPrices({
              pricesList: state.pricesList,
              priceChanges: state.priceChanges,
            });
          },
        );
      }

      return () => {
        if (websocket.current) {
          websocket.current.close();
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency, metal, weightUnit]);

    return {
      liveChartsPrices,
      setLiveChartsPrices,
    };
  };
  return useLiveChartsPrice;
};
