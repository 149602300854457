import { Translate } from 'next-translate';

import {
  ELanguageTags,
  ELiveChartsPeriod,
  EMetal,
  EUnit,
  SelectOption as SelectOptionProps,
} from '@core/type';

import { ChipPeriod } from './interface-charts';

export const chips: ChipPeriod[] = [
  { period: ELiveChartsPeriod.DAY, text: 'chart.periodButtons.period1d' },
  { period: ELiveChartsPeriod.WEEK, text: 'chart.periodButtons.period1w' },
  {
    period: ELiveChartsPeriod.MONTH,
    text: 'chart.periodButtons.period1m',
  },
  { period: ELiveChartsPeriod.THREE_MONTHS, text: 'chart.periodButtons.period3m' },
  { period: ELiveChartsPeriod.SIX_MONTHS, text: 'chart.periodButtons.period6m' },
  { period: ELiveChartsPeriod.YEAR, text: 'chart.periodButtons.period1y' },
  { period: ELiveChartsPeriod.FIVE_YEARS, text: 'chart.periodButtons.period5y' },
  { period: ELiveChartsPeriod.TEN_YEARS, text: 'chart.periodButtons.period10y' },
  { period: ELiveChartsPeriod.ALL, text: 'chart.periodButtons.periodAll' },
];

export const chipsMobile: ChipPeriod[] = [
  { period: ELiveChartsPeriod.DAY, text: 'chart.periodButtons.period1d' },
  { period: ELiveChartsPeriod.WEEK, text: 'chart.periodButtons.period1w' },
  {
    period: ELiveChartsPeriod.MONTH,
    text: 'chart.periodButtons.period1m',
  },
  { period: ELiveChartsPeriod.YEAR, text: 'chart.periodButtons.period1y' },
  { period: ELiveChartsPeriod.ALL, text: 'chart.periodButtons.periodAll' },
];

export const getSecondPrefix = (metal: EMetal, language: ELanguageTags): string => {
  if (language === ELanguageTags.FR_FR) {
    if (metal === EMetal.GOLD || metal === EMetal.SILVER) {
      return `l'`;
    }
    return 'le ';
  }

  if (language === ELanguageTags.IT_IT) {
    if (metal === EMetal.GOLD || metal === EMetal.SILVER) {
      return `l'`;
    }
    return 'il ';
  }
  return '';
};

export const getFirstPrefix = (metal: EMetal, language: ELanguageTags): string => {
  if (language === ELanguageTags.FR_FR) {
    if (metal === EMetal.GOLD || metal === EMetal.SILVER) {
      return `de l'`;
    }
    return 'du ';
  }

  if (language === ELanguageTags.IT_IT) {
    if (metal === EMetal.GOLD || metal === EMetal.SILVER) {
      return `dell'`;
    }
    return 'del ';
  }

  return '';
};

export const getTranslationKeyFromPeriod = (period: ELiveChartsPeriod): string => {
  if (period === ELiveChartsPeriod.DAY) {
    return 'chart.periods.period1d';
  }

  if (period === ELiveChartsPeriod.WEEK) {
    return 'chart.periods.period1w';
  }

  if (period === ELiveChartsPeriod.MONTH) {
    return 'chart.periods.period1m';
  }

  if (period === ELiveChartsPeriod.THREE_MONTHS) {
    return 'chart.periods.period3m';
  }

  if (period === ELiveChartsPeriod.SIX_MONTHS) {
    return 'chart.periods.period6m';
  }

  if (period === ELiveChartsPeriod.YEAR) {
    return 'chart.periods.period1y';
  }

  if (period === ELiveChartsPeriod.FIVE_YEARS) {
    return 'chart.periods.period5y';
  }

  if (period === ELiveChartsPeriod.TEN_YEARS) {
    return 'chart.periods.period10y';
  }

  return 'chart.periods.periodAll';
};

export const getBreadcrumb = (metal: EMetal) => {
  switch (metal) {
    case EMetal.GOLD:
      return 'chart.gold';
    case EMetal.SILVER:
      return 'chart.silver';
    case EMetal.PLATINUM:
      return 'chart.platinum';
    case EMetal.PALLADIUM:
      return 'chart.palladium';
  }
};

export const getTranslationKeyFromWeightUnit = (unit: EUnit): string => {
  if (unit === EUnit.OUNCE) {
    return 'chart.weightUnits.oz';
  }

  if (unit === EUnit.GRAM) {
    return 'chart.weightUnits.gram';
  }

  return 'chart.weightUnits.kg';
};

export const getOptionsForWeightUnit = (t: Translate): SelectOptionProps[] => [
  { label: t('chart.weightUnits.oz'), value: EUnit.OUNCE, content: t('chart.weightUnits.oz') },
  { label: t('chart.weightUnits.gram'), value: EUnit.GRAM, content: t('chart.weightUnits.gram') },
  { label: t('chart.weightUnits.kg'), value: EUnit.KILOGRAM, content: t('chart.weightUnits.kg') },
];

export const getOptionsForMetal = (tProduct: Translate): SelectOptionProps[] => [
  {
    label: tProduct('metalTypes.gold'),
    value: EMetal.GOLD,
    content: tProduct('metalTypes.gold'),
  },
  {
    label: tProduct('metalTypes.silver'),
    value: EMetal.SILVER,
    content: tProduct('metalTypes.silver'),
  },
  {
    label: tProduct('metalTypes.platinum'),
    value: EMetal.PLATINUM,
    content: tProduct('metalTypes.platinum'),
  },
  {
    label: tProduct('metalTypes.palladium'),
    value: EMetal.PALLADIUM,
    content: tProduct('metalTypes.palladium'),
  },
];
