import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import {
  Box,
  Image,
  LinkTranslatedStyledAsLink,
  Paper,
  TextWithLineLimit,
  Typography,
} from '@core/component';
import { LinkTranslatedLegacyProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextCurrency, useContextMediaQuery } from '@core/context';
import { useLanguage, useProductLinkProps } from '@core/hook';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import { EActionAttribute, EMetal, ESize, ETypographyVariant } from '@core/type';
import { HomepageProductsEntity } from '@core/type/api';
import { formatCurrency } from '@core/util';

const PaperBox = styled.span(({ isLarge }: { isLarge: boolean }) => {
  const { mediaQuery } = useContextMediaQuery();
  const isLargeDesktop: boolean = mediaQuery === ESize.XL;

  return css`
    display: flex;
    text-decoration: none;
    color: inherit;
    padding: ${isLarge
      ? isLargeDesktop
        ? '72px 64px'
        : '40px 64px'
      : isLargeDesktop
        ? '72px 40px'
        : '40px'};
    align-items: center;
    height: 100%;
  `;
});

const TextBox = styled.div(
  ({ isLarge, imageSize }: { isLarge: boolean; imageSize: string }) => css`
    width: ${isLarge ? `calc(100% - ${imageSize})` : '100%'};
  `,
);

const ImageBox = styled.div(
  ({
    isSmall,
    imageSize,
    isHoverText,
  }: {
    isSmall: boolean;
    imageSize: string;
    isHoverText: boolean;
  }) => css`
    position: relative;
    width: ${imageSize};
    height: ${isSmall && isHoverText ? '82px' : imageSize};
    transition: 0.4s ease-in-out;
  `,
);

export const ProductsPaperBox = ({
  product,
  isLargeBox,
  isReversed,
  metal,
}: {
  product: HomepageProductsEntity;
  isLargeBox?: boolean;
  isReversed?: boolean;
  metal: EMetal;
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isHoverImage, setIsHoverImage] = useState<boolean>(false);
  const [isHoverText, setIsHoverText] = useState<boolean>(false);

  const { isDesktop, isPhone, mediaQuery, isMobile } = useContextMediaQuery();

  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  const isLarge = isLargeBox && isDesktop;
  const isSmall = !isLargeBox && isDesktop;
  const isLargeDesktop: boolean = mediaQuery === ESize.XL;
  const imageSize = isMobile ? '175px' : isLarge && isLargeDesktop ? '320px' : '202px';

  const { title, subtitle, medias, totalPrice, slug } = product;

  const linkProps: Omit<LinkTranslatedLegacyProps, 'children'> = useProductLinkProps({
    slug,
    metalIso: metal,
  });

  return (
    <Paper
      hasElevation={!isHover}
      height={'100%'}
      margin={isDesktop ? 'auto' : isPhone ? '0 auto' : '0'}
      maxWidth={isPhone ? '362px' : 'none'}
      width={isPhone ? 'calc(100vw - 40px)' : undefined}
      borderRadius={'16px'}
    >
      <LinkTranslatedStyledAsLink {...linkProps}>
        <PaperBox
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          isLarge={isLarge}
        >
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={!isLarge ? 'column' : isReversed ? 'row-reverse' : 'row'}
            gap={!isLarge ? (isDesktop ? '24px' : '40px') : '0'}
            alignItems={'center'}
          >
            <ImageBox
              onMouseEnter={() => setIsHoverImage(true)}
              onMouseLeave={() => setIsHoverImage(false)}
              isSmall={isSmall}
              isHoverText={isHoverText}
              imageSize={imageSize}
            >
              <Box opacity={isHoverImage ? 0 : 1} transition={'opacity .3s linear .1s'}>
                <Image
                  src={medias[0]?.mediaUrl || getStaticPathImage('homepage/gold-1kilo-mobile.jpg')}
                  blurDataURL={
                    medias[0]?.mediaUrl
                      ? undefined
                      : getStaticPathImageBlurData(EFilenameBlur['homepage/gold-1kilo-mobile.jpg'])
                  }
                  alt={medias[0]?.alt || tWidget('home.goldImgAlt')}
                />
              </Box>

              <Box opacity={isHoverImage ? 1 : 0} transition={'opacity .3s linear .1s'}>
                <Image
                  src={medias[1]?.mediaUrl || getStaticPathImage('homepage/gold-1kilo.jpg')}
                  blurDataURL={
                    medias[1]?.mediaUrl
                      ? undefined
                      : getStaticPathImageBlurData(EFilenameBlur['homepage/gold-1kilo.jpg'])
                  }
                  alt={medias[1]?.alt || tWidget('home.goldImgAlt')}
                />
              </Box>
            </ImageBox>

            <TextBox
              onMouseEnter={() => setIsHoverText(true)}
              onMouseLeave={() => setIsHoverText(false)}
              isLarge={isLarge}
              imageSize={imageSize}
            >
              <Box
                paddingLeft={!isReversed && isLarge ? '24px' : '0'}
                paddingRight={isReversed && isLarge ? '24px' : '0'}
                textAlign={'center'}
              >
                <Typography
                  as={EActionAttribute.H3}
                  variant={ETypographyVariant.BODY1}
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  marginBottom={isDesktop ? '16px' : '8px'}
                >
                  {title}
                </Typography>

                <Typography variant={ETypographyVariant.H4}>
                  {formatCurrency(language, currency, totalPrice)}
                </Typography>

                <Box
                  overflow={'hidden'}
                  paddingTop={'24px'}
                  maxHeight={isDesktop && !isHoverText ? '0' : '120px'}
                  transition={'.4s ease-in-out'}
                  textOverflow={'ellipsis'}
                >
                  <TextWithLineLimit lines={4}>{subtitle}</TextWithLineLimit>
                </Box>
              </Box>
            </TextBox>
          </Box>
        </PaperBox>
      </LinkTranslatedStyledAsLink>
    </Paper>
  );
};
