export enum ELiveChartsPeriod {
  DAY = '1d',
  WEEK = '1w',
  MONTH = '1m',
  THREE_MONTHS = '3m',
  SIX_MONTHS = '6m',
  YEAR = '1y',
  FIVE_YEARS = '5y',
  TEN_YEARS = '10y',
  ALL = 'all',
}
