import { useEventMixpanel } from '@mixpanel';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { Box, Button, Image, Typography } from '@core/component';
import {
  currencyIsoToCurrencySlug,
  DEFAULT_AMOUNT_VALUE,
  metalToSlug,
  Translate,
} from '@core/constant';
import { useContextCurrency, useContextMediaQuery, useContextRouting } from '@core/context';
import { useLanguage } from '@core/hook';
import { getStaticPathImage } from '@core/media';
import { EFontWeight, EIcon, EMixpanelEventName, ERouting } from '@core/type';
import { InvestmentChartsResponse } from '@core/type/api';

export const InvestmentLinkBlock = ({
  metalIso,
  initialInvestment,
  monthlyInvestment,
}: Partial<
  Pick<InvestmentChartsResponse, 'metalIso' | 'initialInvestment' | 'monthlyInvestment'>
>) => {
  const { trackInvestmentChartsEvent } = useEventMixpanel();
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);
  const { isMobile } = useContextMediaQuery();
  const { setRoute } = useContextRouting();
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  return (
    <Box marginTop={isMobile ? '30px' : '0'}>
      <Typography fontWeight={EFontWeight.BOLD} marginBottom={'30px'}>
        <Image
          alt={'Hand icon'}
          width={24}
          height={24}
          style={{ objectFit: 'contain', marginBottom: '-6px' }}
          src={getStaticPathImage('charts/icon-hand.png')}
        />
        <Trans
          i18nKey={`${Translate.common.WIDGET}:ifIHadInvested.strategiesMatching`}
          components={{
            br: <br />,
          }}
        />
      </Typography>
      <Box maxWidth={'273px'} margin={isMobile ? '0 auto' : '0'}>
        <Button
          onClick={() => {
            trackInvestmentChartsEvent({
              eventName: EMixpanelEventName.INVESTMENT_CHARTS_STRATEGIES,
              metalIso,
              initialInvestment,
              monthlyInvestment,
            });

            setRoute(ERouting.SAVING_ASSISTANT, {
              amount: String(initialInvestment || DEFAULT_AMOUNT_VALUE),
              currency: currencyIsoToCurrencySlug[currency],
              category: metalToSlug[language][metalIso],
            });
          }}
          endIcon={EIcon.ARROW_BIG}
          isFullWidth
        >
          {tWidget('ifIHadInvested.seeMyStrategies')}
        </Button>
      </Box>
    </Box>
  );
};
