import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';
import { useState } from 'react';

import {
  Box,
  CardArticleCharts,
  Grid,
  LinkTranslatedStyledAsLink,
  SkeletonTypography,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery, useContextProfile, useContextUser } from '@core/context';
import { useQueryApi } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  EIcon,
  ELinkAnimation,
  EPosition,
  ERouting,
  ETypographyVariant,
  EZIndexName,
  WithTranslation,
} from '@core/type';
import { BlogArticleEntity, EQueryKey, HomepageArticlesResponse } from '@core/type/api';
import { LeadMagnet, SecondaryBannerSpotlight } from '@core/widget';

const Article = ({ article }: { article: BlogArticleEntity }) => {
  const { isPhone } = useContextMediaQuery();
  const { slug, hasVideo, title, subtitle, image, readingTime, category, tags } = article;

  return (
    <Grid columnSpacing={30} rowSpacing={56} xs={12} md={6} lg={4}>
      <CardArticleCharts
        hasVideo={hasVideo}
        title={title}
        subtitle={subtitle}
        headerColor={EColor.PRIMARY}
        image={image}
        readingTime={readingTime}
        category={category}
        tags={tags}
        slug={slug}
        categoryColor={EColor.ACCENT}
        actionAttribute={EActionAttribute.H2}
        linkTranslatedProps={{
          keyRoute: ERouting.BLOG_ARTICLE,
          query: {
            category: category.slug,
            slug,
          },
        }}
      />

      {isPhone && (
        <Box display={'flex'} justifyContent={'flex-end'} marginTop={'72px'}>
          <LinkToBlogPage />
        </Box>
      )}
    </Grid>
  );
};

const LinkToBlogPage = () => (
  <LinkTranslatedStyledAsLink
    keyRoute={ERouting.BLOG_HOME}
    color={EColor.ACCENT}
    endIcon={EIcon.ARROW_DOWN}
    iconPosition={EPosition.RIGHT}
    iconColor={EColor.SECONDARY}
    animation={ELinkAnimation.ARROW_RIGHT}
    iconOrientation={EPosition.RIGHT}
    isIconStroke
  >
    <Trans i18nKey={`${Translate.common.WIDGET}:home.discoverOurBlog`} />
  </LinkTranslatedStyledAsLink>
);

const ArticlesBlockContainer = ({ i18n: { t } }: WithTranslation) => {
  const [isSubscriptionSuccessful, setIsSubscriptionSuccessful] = useState(false);

  const { hasNewsletterSubscription } = useContextProfile();
  const { isDesktop, isPhone } = useContextMediaQuery();
  const { isLogged } = useContextUser();

  const hasSubscription = isLogged && hasNewsletterSubscription;
  const {
    data: articles,
    isLoading,
    isError,
  } = useQueryApi<HomepageArticlesResponse>(EQueryKey.COMPONENT_ARTICLES);

  const articlesToShow = () => {
    if (isDesktop) {
      return articles?.blogArticles.map((el) => <Article article={el} key={el.slug} />);
    } else {
      return <Article article={articles?.blogArticles[0]} />;
    }
  };

  if (isError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <Box marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  return (
    <Box
      marginTop={isPhone ? '120px' : '160px'}
      position={'relative'}
      zIndex={EZIndexName.OVER_DEFAULT}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box maxWidth={'750px'}>
          <Typography
            as={EActionAttribute.H2}
            variant={ETypographyVariant.H1}
            marginBottom={'24px'}
          >
            {t('home.articlesTitle')}
          </Typography>

          <Typography variant={ETypographyVariant.BODY1}>{t('home.articlesDesc')}</Typography>
        </Box>
        {!isPhone && (
          <Box display={'flex'} alignItems={'flex-end'} marginLeft={'16px'} flexShrink={'0'}>
            <LinkToBlogPage />
          </Box>
        )}
      </Box>

      {articles?.blogArticles?.length > 0 && (
        <Box marginTop={isPhone ? '56px' : '88px'}>
          <Grid isContainer columns={12} columnSpacing={30} rowSpacing={56}>
            {articlesToShow()}

            <Grid xs={12} md={6} lg={4}>
              <Box borderRadius={'16px'} overflow={'hidden'}>
                {articles?.leadMagnet &&
                  (!isLogged || isSubscriptionSuccessful || hasSubscription) && (
                    <LeadMagnet leadMagnet={articles?.leadMagnet} requestPage={ERouting.HOME} />
                  )}
                {isLogged && !isSubscriptionSuccessful && !hasSubscription && (
                  <SecondaryBannerSpotlight
                    onSuccessfulAnimationEnd={() => setIsSubscriptionSuccessful(true)}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ArticlesBlock = withTranslation(ArticlesBlockContainer, Translate.common.WIDGET);
