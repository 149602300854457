import { TrustpilotLong, TrustpilotNextScript, getTrustpilotTag } from '@trustpilot';
import Trans from 'next-translate/Trans';
import { useEffect, useState } from 'react';

import { Box, SkeletonTypography, Typography } from '@core/component';
import {
  TRUSTPILOT_API_URL,
  TRUSTPILOT_STARS,
  TRUSTPILOT_TAG,
  TRUSTPILOT_WIDGET_URL,
  Translate,
  trustpilotIds,
} from '@core/constant';
import { useContextMediaQuery, useContextUtil } from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import { Logger } from '@core/logger';
import {
  EActionAttribute,
  EColor,
  ELanguage,
  ELanguageTags,
  ERouting,
  ETypographyVariant,
  EZIndexName,
} from '@core/type';
import { EQueryKey, TrustpilotTotalUsersEntity } from '@core/type/api';
import { SanityWidget } from '@core/type/sanity';
import { addParameters, formatNumber, getLanguageFromTags, getLanguageTags } from '@core/util';

import { getEnvironmentPublic } from '../../../helpers';

export type HomepageTrustpilotProps = { language: ELanguageTags; tag: string };
export type HomepageTrustpilotSanityResponse = {
  language: ELanguageTags;
  tag: string;
  apiParams: { language: ELanguageTags };
};

export const HomepageTrustpilot = ({ language, tag }: HomepageTrustpilotProps) => {
  const { isPhone } = useContextMediaQuery();
  const {
    data: trustpilot,
    isError,
    isLoading,
  } = useQueryApi<TrustpilotTotalUsersEntity>(EQueryKey.TRUSTPILOT);
  const { nonce, isBotDetected } = useContextUtil();
  const [shouldShowWidget, setShouldShowWidget] = useState<boolean>(false);
  const { language: languageSelected } = useLanguage();

  useEffect(() => {
    if (language && tag) {
      fetch(
        addParameters(
          `${TRUSTPILOT_API_URL}/v1/business-units/${trustpilotIds.BUSINESSUNIT_ID}/reviews`,
          {
            apikey: getEnvironmentPublic().trustpilotApiKey,
            tagValue: getTrustpilotTag(language, tag),
            perPage: '1',
            stars: TRUSTPILOT_STARS,
            language: getLanguageFromTags(language),
          },
        ),
      )
        .then((response) => response.json())
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          setShouldShowWidget(response?.reviews?.length);
        })
        .catch((error) => {
          Logger.logError(error, { isException: true, route: ERouting.HOME, language });
        });
    }
  }, [tag, language]);

  if (isError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <Box marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  return (
    <>
      {getEnvironmentPublic().enableTrustpilot && !isBotDetected && (
        <TrustpilotNextScript url={TRUSTPILOT_WIDGET_URL} nonce={nonce} />
      )}

      {!!shouldShowWidget && (
        <Box
          marginTop={isPhone ? '24px' : '104px'}
          position={'relative'}
          zIndex={EZIndexName.OVER_DEFAULT}
        >
          <Box>
            <Typography
              as={EActionAttribute.H2}
              variant={ETypographyVariant.H1}
              marginBottom={'64px'}
            >
              <Trans
                i18nKey={`${Translate.common.WIDGET}:home.usersTrust`}
                values={{ count: formatNumber(languageSelected, trustpilot?.totalUsers) }}
                components={{
                  green: (
                    <Typography
                      as={EActionAttribute.SPAN}
                      variant={ETypographyVariant.H1}
                      color={EColor.SUCCESS}
                    />
                  ),
                  br: <br />,
                }}
              />
            </Typography>

            <TrustpilotLong locale={language} tag={tag} />
          </Box>
        </Box>
      )}
    </>
  );
};

export const getHomepageTrustpilot = (section: SanityWidget): JSX.Element => {
  const params = section as unknown as HomepageTrustpilotSanityResponse;
  return (
    <HomepageTrustpilot
      language={
        (getLanguageTags(params.language as unknown as ELanguage) as ELanguageTags) ??
        (params.apiParams?.language as ELanguageTags)
      }
      tag={params?.tag ?? TRUSTPILOT_TAG}
    />
  );
};
