import { Datetime } from './date';
import { Price } from './unit';

export type LiveChartsPriceEntity = {
  date: Datetime;
  price: Price;
  priceOunce: Price;
};

export enum ESign {
  POSITIVE = '+',
  NEGATIVE = '-',
}

type PriceChangesEntity = {
  metalPrice: number;
  difference: number;
  changeSign: ESign;
  percentage: {
    number: number;
  };
};

export type LiveCharts = {
  pricesList: LiveChartsPriceEntity[];
  priceChanges: PriceChangesEntity;
};
