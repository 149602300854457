import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import { PostLeadMagnetEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const postLeadMagnet = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PostLeadMagnetEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.leadMagnet.LEAD_MAGNET,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class LeadMagnetAPI {
  public static readonly postLeadMagnet = postLeadMagnet;
}
