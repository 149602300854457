import { GetSanityComponentHandler, SanityWidget } from '@core/type/sanity';

export const renderSanitySections = (
  sections: SanityWidget[],
  pageId: string,
  getCmsComponent: GetSanityComponentHandler,
) => {
  return sections.map((section) => {
    const innerSections = (section as SanityWidget & { sections: SanityWidget[] })?.sections;
    if (innerSections?.length > 0) {
      renderSanitySections(innerSections, pageId, getCmsComponent);
    }

    return getCmsComponent(section, pageId);
  });
};
