import withTranslation from 'next-translate/withTranslation';

import { LinkTranslatedStyledAsButton } from '@core/component';
import { Translate } from '@core/constant';
import { useContextRedirectAfterSignInRoute, useContextUser } from '@core/context';
import { EActionAttribute, EIcon, ERouting, WithTranslation } from '@core/type';
import { ChartChoices } from '@core/util';

const ButtonSetMarketAlertContainer = ({
  metal,
  i18n: { t },
}: Pick<ChartChoices, 'metal'> & WithTranslation) => {
  const { isLogged } = useContextUser();
  const { setRoute, setQuery } = useContextRedirectAfterSignInRoute();

  return (
    <LinkTranslatedStyledAsButton
      keyRoute={ERouting.ALERT_SETTINGS}
      query={{ metalIso: metal }}
      as={EActionAttribute.LINK}
      startIcon={EIcon.ALERT}
      isFullWidth
      onClick={() => {
        if (!isLogged) {
          setRoute(ERouting.ALERT_SETTINGS);
          setQuery({ metalIso: metal });
        }
      }}
    >
      {t('chart.setMarketAlertBtn')}
    </LinkTranslatedStyledAsButton>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ButtonSetMarketAlert = withTranslation(
  ButtonSetMarketAlertContainer,
  Translate.common.WIDGET,
);
