import { EUnit } from '@core/type';
import { ETablePeriod } from '@core/type/api';

import { DynamicTranslationsValuesType } from './interface-spot-price-history';

export const DYNAMIC_TRANSLATIONS_VALUES: DynamicTranslationsValuesType = {
  [ETablePeriod.ONE_HOUR]: { period: EUnit.HOUR, amount: 1, position: 1 },
  [ETablePeriod.SIX_HOURS]: { period: EUnit.HOUR, amount: 6, position: 2 },
  [ETablePeriod.TWELVE_HOURS]: { period: EUnit.HOUR, amount: 12, position: 3 },
  [ETablePeriod.SIXTEEN_HOURS]: { period: EUnit.HOUR, amount: 16, position: 4 },
  [ETablePeriod.TWENTY_HOURS]: { period: EUnit.HOUR, amount: 20, position: 5 },
  [ETablePeriod.ONE_DAY]: { period: EUnit.DAY, amount: 1, position: 6 },
  [ETablePeriod.ONE_WEEK]: { period: EUnit.WEEK, amount: 1, position: 7 },
  [ETablePeriod.ONE_MONTH]: { period: EUnit.MONTH, amount: 1, position: 8 },
  [ETablePeriod.SIX_MONTHS]: { period: EUnit.MONTH, amount: 6, position: 9 },
  [ETablePeriod.ONE_YEAR]: { period: EUnit.YEAR, amount: 1, position: 10 },
  [ETablePeriod.FIVE_YEARS]: { period: EUnit.YEAR, amount: 5, position: 11 },
  [ETablePeriod.TEN_YEARS]: { period: EUnit.YEAR, amount: 10, position: 12 },
  [ETablePeriod.TWENTY_YEARS]: { period: EUnit.YEAR, amount: 20, position: 13 },
  [ETablePeriod.FIFTY_YEARS]: { period: EUnit.YEAR, amount: 50, position: 14 },
};

export const T_SINGULAR = 'one';
export const T_PLURAL = 'other';
