import { Box, LinkTranslatedStyledAsButton } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { getSanityPageRoute } from '@core/sanity/utils';
import { EActionAttribute, EPosition, StyleRuleAlignContent } from '@core/type';
import { SanityWidget } from '@core/type/sanity';

import {
  ButtonGroupEntity,
  ButtonGroupPosition,
  justifyContentAlign,
} from './interface-button-group';

const ButtonGroup = ({ align = EPosition.CENTER, buttons }: ButtonGroupEntity) => {
  const { isPhone } = useContextMediaQuery();

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const isPhoneWith4Buttons = isPhone && buttons?.length === 4;

  return (
    <Box
      gap={'16px'}
      display={'flex'}
      justifyContent={justifyContentAlign[align] as StyleRuleAlignContent}
      flexWrap={isPhoneWith4Buttons ? 'wrap' : 'nowrap'}
    >
      {buttons?.map(
        (
          { href: hrefProp, keyRoute, language, slug, metalIso, text, internalLink, variant },
          index,
        ) => {
          const href = internalLink?._ref
            ? getSanityPageRoute(keyRoute, language, slug, metalIso)
            : hrefProp;

          return (
            <Box
              key={index}
              width={isPhoneWith4Buttons ? 'calc(50% - 8px)' : '100%'}
              maxWidth={'328px'}
              display={'inline-grid'}
            >
              <LinkTranslatedStyledAsButton
                as={EActionAttribute.LINK}
                href={href}
                isFullWidth
                locale={internalLink?._ref ? false : undefined}
                variant={variant}
              >
                {text}
              </LinkTranslatedStyledAsButton>
            </Box>
          );
        },
      )}
    </Box>
  );
};

export const getButtonGroupComponent = (section: SanityWidget) => {
  const buttonSection = section as SanityWidget & ButtonGroupEntity;

  return (
    <ButtonGroup
      align={buttonSection?.align as ButtonGroupPosition}
      buttons={buttonSection?.buttons}
    />
  );
};
