import { useMemo, type ReactNode } from 'react';

import { liveChartsPriceContext } from '@core/context';
import { LiveCharts } from '@core/type';
import { LiveChartsPriceHook, LiveChartsPriceState } from '@core/type/context';

import { storeHook } from '../helpers';
import { makeUseLiveChartsPrice } from '../hook';

export const ProviderLiveChartsPrice = ({
  wsHost,
  children,
  liveChartsInitialize,
}: {
  wsHost: string;
  children?: ReactNode;
  liveChartsInitialize?: LiveCharts;
}) => {
  const useLiveChartsPrice = useMemo(() => makeUseLiveChartsPrice(wsHost), [wsHost]);

  const hooksLiveChartsPrice = storeHook<LiveChartsPriceState, LiveChartsPriceHook>(
    {
      liveChartsPrices: {
        pricesList: liveChartsInitialize.pricesList,
        priceChanges: liveChartsInitialize.priceChanges,
      },
    },
    useLiveChartsPrice,
  );

  return (
    <liveChartsPriceContext.Provider value={hooksLiveChartsPrice}>
      {children}
    </liveChartsPriceContext.Provider>
  );
};
