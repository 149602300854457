import { EUnit, WeightUnitType } from '@core/type';

export enum ShortenedWeightSlug {
  OUNCE = 'oz',
  GRAM = 'gram',
  KILOGRAM = 'kilogram',
}

export const weightToShortenedSlug: { [key in WeightUnitType]: ShortenedWeightSlug } = {
  [EUnit.OUNCE]: ShortenedWeightSlug.OUNCE,
  [EUnit.GRAM]: ShortenedWeightSlug.GRAM,
  [EUnit.KILOGRAM]: ShortenedWeightSlug.KILOGRAM,
};
