import withTranslation from 'next-translate/withTranslation';
import { memo } from 'react';

import { Box, Grid, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EActionAttribute, ETypographyVariant, WithTranslation } from '@core/type';
import { EQueryKey, ProductPageEntity } from '@core/type/api';

import { ProductCardLink } from '../card';

const DESKTOP_MAX_CARD_LENGTH = 3;
const MOBILE_MAX_CARD_LENGTH = 4;

const SectionSuggestedProductsContainer = ({
  slug,
  i18n: { t },
}: { slug: string } & WithTranslation) => {
  const { isDesktop, isMobile } = useContextMediaQuery();

  const { data } = useQueryApi<ProductPageEntity, { slug: string }>(EQueryKey.PRODUCT, { slug });

  const products = data?.suggestedProducts?.slice(
    0,
    isDesktop ? DESKTOP_MAX_CARD_LENGTH : MOBILE_MAX_CARD_LENGTH,
  );

  return (
    <Box as={EActionAttribute.SECTION}>
      <Typography
        textAlign="center"
        marginBottom={'24px'}
        as={EActionAttribute.H4}
        variant={ETypographyVariant.H5}
      >
        {t('productSuggestion.productsSuggestionTitle')}
      </Typography>
      <Grid
        isContainer
        rowSpacing={30}
        columnSpacing={
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          isMobile ? 6 : 30
        }
      >
        {products?.map((product) => (
          <Grid key={product.id} xs={6} lg={4}>
            <ProductCardLink titleTag={EActionAttribute.H5} product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const SuggestedProducts = memo(
  withTranslation(SectionSuggestedProductsContainer, Translate.common.WIDGET),
);
