import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { Translate } from '@core/constant';
import { useContextProfile, useContextUser } from '@core/context';
import { useValidator } from '@core/hook';

export const schema = object({
  email: string().email().required(),
});

export const useSpotlightForm = () => {
  const { t: tForm } = useTranslation(Translate.common.FORM);

  const { email: emailUser } = useContextUser();
  const { setHasNewsletterSubscription } = useContextProfile();

  const [isClosed, setIsClosed] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const { control, handleSubmit, formState, trigger, reset, setError } = useForm({
    defaultValues: {
      email: emailUser ?? '',
    },
    resolver: useValidator(schema, tForm),
  });

  const { isSubmitSuccessful, isSubmitting } = formState;

  const onSuccess = () => {
    setHasNewsletterSubscription(true);
  };

  return {
    isClosed,
    setIsClosed,
    isClosing,
    setIsClosing,
    control,
    handleSubmit,
    trigger,
    reset,
    setError,
    isSubmitSuccessful,
    tForm,
    isSubmitting,
    onSuccess,
  };
};
