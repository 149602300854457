import { MutableRefObject, TouchEvent, useEffect } from 'react';

export const SCROLL_EXTRA_DELTA_X = 5;

export const useCarouselSwipe = (
  startTouchXRef: MutableRefObject<number>,
  endTouchXRef: MutableRefObject<number>,
  startTouchYRef: MutableRefObject<number>,
) => {
  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      startTouchYRef.current = event?.touches[0]?.clientY;
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (
        startTouchYRef.current === null ||
        startTouchXRef.current === null ||
        endTouchXRef.current === null
      ) {
        return;
      }

      const deltaX = Math.abs(startTouchXRef.current - endTouchXRef.current);
      const deltaY = Math.abs(startTouchYRef.current - event.touches[0]?.clientY);

      if (deltaX + SCROLL_EXTRA_DELTA_X >= deltaY) {
        event.preventDefault();
      }
    };

    window.addEventListener('touchstart', handleTouchStart as unknown as EventListener);
    window.addEventListener('touchmove', handleTouchMove as unknown as EventListener, {
      passive: false,
    });

    return () => {
      window.removeEventListener('touchstart', handleTouchStart as unknown as EventListener);
      window.removeEventListener('touchmove', handleTouchMove as unknown as EventListener);
    };
  }, []);
};
