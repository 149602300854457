import styled, { css } from 'styled-components';

import { Typography } from '@core/component';
import { TypographyProps } from '@core/component/interface';

export const TextItalic = styled(Typography)(
  ({ fontWeight }: TypographyProps) => css`
    font-style: italic;
    ${fontWeight ? '' : 'font-weight: inherit;'}
  `,
);
