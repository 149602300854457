import { Box } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { StyleRuleFlexDirection } from '@core/type';
import { EWidgetGridSize, GetSanityComponentHandler, SanityWidget } from '@core/type/sanity';
import { renderSanitySections } from '@core/util';

import {
  WidgetGridComponentEntity,
  WidgetGridProps,
  WidgetGridSizeProps,
} from './interface-widget-grid';

const getGridSizeProps = (gridSize: EWidgetGridSize): WidgetGridSizeProps => {
  switch (gridSize) {
    case EWidgetGridSize.GRID_25_75:
      return { left: '25%', right: '75%' };
    case EWidgetGridSize.GRID_33_66:
      return { left: '33%', right: '66%' };
    case EWidgetGridSize.GRID_50_50:
      return { left: '50%', right: '50%' };
    case EWidgetGridSize.GRID_66_33:
      return { left: '66%', right: '33%' };
    case EWidgetGridSize.GRID_75_25:
      return { left: '75%', right: '25%' };
    case EWidgetGridSize.GRID_33_33_33:
      return { left: '33%', center: '33%', right: '33%' };
    case EWidgetGridSize.GRID_100:
      return { left: '100%', right: '100%' };
    default:
      return { left: '50%', right: '50%' };
  }
};

export const WidgetGrid = ({
  leftColumnSections,
  centerColumnSections,
  rightColumnSections,
  gridGap,
  gridGapOnMobile,
  gridSize,
  gridDirectionOnMobile,
  pageId,
  getSanityComponent,
}: WidgetGridProps) => {
  const { isMobile } = useContextMediaQuery();

  const {
    left: leftColumnWidth,
    center: centerColumnWidth,
    right: rightColumnWidth,
  } = getGridSizeProps(gridSize);
  const isFullWidthOnMobile = isMobile && gridDirectionOnMobile !== 'row';

  return (
    <Box
      display={'flex'}
      gap={isMobile ? gridGapOnMobile ?? gridGap : gridGap}
      flexDirection={isMobile ? gridDirectionOnMobile : undefined}
    >
      <Box width={isFullWidthOnMobile ? 'auto' : leftColumnWidth}>
        {renderSanitySections(leftColumnSections, pageId, getSanityComponent)}
      </Box>
      {centerColumnSections?.length > 0 && gridSize === EWidgetGridSize.GRID_33_33_33 && (
        <Box width={isFullWidthOnMobile ? 'auto' : centerColumnWidth}>
          {renderSanitySections(centerColumnSections, pageId, getSanityComponent)}
        </Box>
      )}
      {rightColumnSections?.length > 0 && (
        <Box width={isFullWidthOnMobile ? 'auto' : rightColumnWidth}>
          {renderSanitySections(rightColumnSections, pageId, getSanityComponent)}
        </Box>
      )}
    </Box>
  );
};

export const getWidgetGrid = (
  section: SanityWidget,
  pageId: string,
  getSanityComponent: GetSanityComponentHandler,
) => {
  const props = section as WidgetGridComponentEntity;

  return (
    <WidgetGrid
      gridSize={props?.gridSize as EWidgetGridSize}
      gridDirectionOnMobile={props?.gridDirectionOnMobile as StyleRuleFlexDirection}
      pageId={pageId}
      leftColumnSections={props?.leftColumnSections || []}
      centerColumnSections={props?.centerColumnSections || []}
      rightColumnSections={props?.rightColumnSections || []}
      gridGap={props?.gridGap}
      gridGapOnMobile={props?.gridGapOnMobile}
      getSanityComponent={getSanityComponent}
    />
  );
};
