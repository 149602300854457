import { Box, ButtonIcon } from '@core/component';
import { IconProps } from '@core/component/interface';
import { EColor, EIcon, EPosition, ESize, EZIndexName } from '@core/type';

export const ButtonArrow = ({
  top,
  orientation,
  onClick,
  isActive,
  height,
  indent,
  ...restProps
}: Pick<
  IconProps,
  'top' | 'orientation' | 'onClick' | 'height' | 'borderRadius' | 'backgroundColor'
> & {
  indent?: string;
  isActive: boolean;
}) => {
  const buttonHeight = height || '40px';
  const buttonIndent = indent || '-20px';

  return (
    <Box
      position={'absolute'}
      top={top || `calc(50% - ${buttonHeight} / 2)`}
      left={orientation === EPosition.LEFT ? buttonIndent : 'auto'}
      right={orientation === EPosition.RIGHT ? buttonIndent : 'auto'}
      zIndex={EZIndexName.HOVER_EFFECT}
      height={buttonHeight}
      width={'40px'}
      opacity={isActive ? 1 : 0}
      transition={'0.3s'}
      pointerEvents={isActive ? 'auto' : 'none'}
    >
      <ButtonIcon
        position={'relative'}
        onClick={onClick}
        padding={'0'}
        backgroundColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
        display={'flex'}
        borderRadius={'50%'}
        alignItems={'center'}
        justifyContent={'center'}
        iconProps={{ type: EIcon.ANGLE, strokeWidth: 1.8, orientation, size: ESize.MD }}
        height={buttonHeight}
        width={'40px'}
        disabled={!isActive}
        pointerEvents={isActive ? 'auto' : 'none'}
        {...restProps}
      />
    </Box>
  );
};
