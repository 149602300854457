import Link from 'next/link';
import { FC, useCallback, useEffect, useRef } from 'react';

import { Language, trustpilotReviewUrl } from '@core/constant';
import { EColor, ELanguageTags, ETypeApp } from '@core/type';
import { environmentApp } from '@core/util';

export type TrustpilotProps = {
  locale: ELanguageTags;
  templateId: string;
  businessunitId: string;
  height: string;
  width: string;
  theme: 'light' | 'dark';
  tag: string;
  star: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    Trustpilot: { loadFromElement: (element: any, isLoading: boolean) => void };
  }
}

export const TrustpilotComponent: FC<TrustpilotProps> = ({
  locale = ELanguageTags.EN_US,
  templateId,
  businessunitId,
  height,
  width,
  theme,
  tag,
  star,
}: TrustpilotProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const linkReview = useCallback(() => {
    return trustpilotReviewUrl[
      locale === ELanguageTags.DEFAULT ? Language.I18N_DEFAULT_LANGUAGE : locale
    ];
  }, [locale]);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [locale]);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={locale}
      data-template-id={templateId}
      data-businessunit-id={businessunitId}
      data-style-height={height}
      data-style-width={width}
      data-theme={theme}
      data-tags={tag}
      data-stars={star}
    >
      {environmentApp.typeApp === ETypeApp.NEXT ? (
        <Link href={linkReview()} target="_blank" rel="noreferrer" color={EColor.ACCENT}>
          Trustpilot
        </Link>
      ) : (
        <a href={linkReview()} target="_blank" rel="noreferrer">
          Trustpilot
        </a>
      )}
    </div>
  );
};
