import type { ReactNode } from 'react';

import { metalIsoContext } from '@core/context';
import { EMetal } from '@core/type';
import { MetalIsoHook, MetalIsoState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useMetalIso } from '../hook';

export const ProviderMetalIso = ({
  children,
  providerMetalIsoInitialize,
}: {
  children?: ReactNode;
  providerMetalIsoInitialize?: EMetal;
}) => {
  const hookMetalIso = storeHook<MetalIsoState, MetalIsoHook>(
    {
      metalIso: providerMetalIsoInitialize,
    },
    useMetalIso,
  );

  return <metalIsoContext.Provider value={hookMetalIso}>{children}</metalIsoContext.Provider>;
};
