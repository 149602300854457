import { Typography } from '@core/component';
import { useLanguage, usePreviousDistinct } from '@core/hook';
import { EActionAttribute, ETypographyVariant } from '@core/type';
import { formatCurrency } from '@core/util';

import { LivePriceProps } from './interface-charts';

export const LivePrice = ({ deskbid, currency, metalPrice }: LivePriceProps) => {
  const { language } = useLanguage();

  const livePrice = metalPrice ?? deskbid;
  const previousLivePrice = usePreviousDistinct(livePrice);

  return (
    <Typography variant={ETypographyVariant.H2} as={EActionAttribute.H3} display="inline-block">
      {formatCurrency(language, currency, livePrice ?? previousLivePrice)}
    </Typography>
  );
};
