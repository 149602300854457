import NextLink from 'next/link';
import { useState } from 'react';

import { Box, Icon, Line, Link } from '@core/component';
import { getSanityPageRoute } from '@core/sanity/utils';
import { EActionAttribute, EColor, EIcon, EPosition } from '@core/type';
import { SanityLinkObjProps, SanityWidget } from '@core/type/sanity';

import { GuideBlockProps } from './interface-guide-block';

export const GuideBlock = ({ links }: GuideBlockProps) => (
  <Box padding={'26px 40px'} borderRadius={'16px'} backgroundColor={EColor.INFO}>
    {links?.map((link, index) => (
      <Box key={index}>
        {index > 0 && <Line />}
        <LinkBlock {...link} />
      </Box>
    ))}
  </Box>
);

const LinkBlock = ({
  href: hrefProp,
  text,
  internalLink,
  language,
  keyRoute,
  metalIso,
  slug,
}: SanityLinkObjProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const href = internalLink?._ref
    ? getSanityPageRoute(keyRoute, language, slug, metalIso)
    : hrefProp;

  return (
    <NextLink
      href={href}
      style={{ textDecoration: 'none' }}
      passHref
      locale={internalLink?._ref ? false : undefined}
    >
      <Box
        cursor={'pointer'}
        padding={'16px 0'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Box paddingRight={'16px'}>
          <Link as={EActionAttribute.SPAN} isUnderlined={isHover}>
            {text}
          </Link>
        </Box>
        <Icon type={EIcon.ARROW_DOWN} orientation={EPosition.RIGHT} />
      </Box>
    </NextLink>
  );
};

export const getGuideBlockComponent = (section: SanityWidget) => {
  const guideSection = section as SanityWidget & GuideBlockProps;

  return <GuideBlock links={guideSection?.links} />;
};
