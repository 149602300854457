import { useState } from 'react';

export const useCarouselSteps = (defaultStep = 0) => {
  const [currentStep, setCurrentStep] = useState(defaultStep);

  const onPrevious = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const onNext = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const onClick = (index: number) => {
    setCurrentStep(index);
  };

  return { onPrevious, onNext, onClick, currentStep };
};
