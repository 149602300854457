import { createContext } from 'react';

import type { HookStore, LiveChartsPriceHook, LiveChartsPriceState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const liveChartsPriceContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, LiveChartsPriceState, LiveChartsPriceHook>>(null);

export const useContextLiveChartsPrice = () => {
  return useFnContext(liveChartsPriceContext);
};

export function useStoreLiveChartsPrice<Selector>(selector: keyof LiveChartsPriceState) {
  return useStoreContext<Selector, LiveChartsPriceState, LiveChartsPriceHook>(
    selector,
    liveChartsPriceContext,
  );
}
