import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';
import { useState } from 'react';
import styled from 'styled-components';

import {
  Box,
  Grid,
  Image,
  Link,
  LinkTranslatedStyledAsLink,
  ModalVideo,
  Typography,
} from '@core/component';
import {
  BUY_PRECIOUS_METALS,
  GA_BUY_SELL_STORE,
  SELL_STORED_METALS,
  STORE_PRECIOUS_METALS,
  Translate,
  metalToSlug,
} from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ELinkAnimation,
  EMetal,
  EPosition,
  ERouting,
  ESize,
  ETypographyVariant,
  EZIndexName,
  ThemePalette,
  WithTranslation,
} from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { SecureBlockProps } from '../interface-home';

const PaperBox = styled.div(
  ({ theme: { palette }, isHover }: { theme: { palette: ThemePalette }; isHover: boolean }) => {
    const { getBoxShadow, getBackgroundColor } = getPaletteHandlers(palette);

    return `
    margin: 0 15px;
    border-radius: 16px;
    transition: 0.3s;
    cursor: pointer;
    box-shadow: ${isHover ? 'none' : getBoxShadow(EColor.ELEVATION_4)};
    padding: 64px 40px 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: ${getBackgroundColor({
      semanticType: EColor.NEUTRAL,
      variant: EColor.LIGHT,
    })};
  `;
  },
);

const SecureBlock = ({
  titleKey,
  text,
  link,
  query,
  linkText,
  videoTitle,
  videoLink,
  image,
}: SecureBlockProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isOpenVideo, setIsOpenVideo] = useState<boolean>(false);

  const { isMobile, isPhone } = useContextMediaQuery();
  const { language } = useLanguage();

  const onOpenModal = () => {
    setIsHover(false);
    setIsOpenVideo(true);
  };

  const onCloseModal = () => {
    setIsHover(false);
    setIsOpenVideo(false);
  };

  return (
    <Grid md={6} lg={4}>
      <Box height={'100%'} paddingBottom={'22px'}>
        <PaperBox
          isHover={isHover}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <LinkTranslatedStyledAsLink keyRoute={link} query={query} isUnderlinedHover={false}>
            <Box textDecoration={'none'} color={EColor.PRIMARY}>
              <Typography as={EActionAttribute.H2}>
                <Link
                  as={EActionAttribute.SPAN}
                  variant={ETypographyVariant.H3}
                  fontWeight={EFontWeight.BOLD}
                  isUnderlined={isHover}
                >
                  <Trans
                    i18nKey={`${Translate.common.WIDGET}:home.${titleKey}`}
                    components={{ br: <br /> }}
                  />
                </Link>
              </Typography>

              <Typography variant={ETypographyVariant.BODY1} margin={'16px 0'}>
                {text}
              </Typography>

              <Box marginBottom={isMobile ? '76px' : '100px'}>
                <Link
                  as={EActionAttribute.SPAN}
                  color={EColor.ACCENT}
                  endIcon={EIcon.ARROW_DOWN}
                  iconPosition={EPosition.RIGHT}
                  iconColor={EColor.SECONDARY}
                  animation={ELinkAnimation.ARROW_RIGHT}
                  iconOrientation={EPosition.RIGHT}
                  isUnderlined={isHover}
                  enableAnimation={isHover}
                  fontSize={'15px'}
                  isIconStroke
                >
                  {linkText}
                </Link>
              </Box>
            </Box>
          </LinkTranslatedStyledAsLink>

          <Box position={'relative'} height={0} bottom={'44px'}>
            <Box position={'absolute'} textAlign={'left'}>
              <span onMouseEnter={() => setIsHover(false)} onMouseLeave={() => setIsHover(true)}>
                <Link
                  as={EActionAttribute.BUTTON}
                  color={EColor.ACCENT}
                  onClick={onOpenModal}
                  startIcon={EIcon.PLAY_CIRCLED}
                  variant={ETypographyVariant.BODY2}
                  isIconFill={false}
                  iconColor={EColor.ACCENT}
                >
                  {videoTitle}
                </Link>
              </span>
            </Box>
          </Box>
          <ModalVideo
            isOpen={isOpenVideo}
            onCloseModal={onCloseModal}
            videoLink={videoLink}
            title={'playVideoTitle'}
            language={language}
          />

          {isPhone && image}
        </PaperBox>
      </Box>
    </Grid>
  );
};

const SecureSavingsBlockContainer = ({ i18n: { t } }: WithTranslation) => {
  const [isOpenVideo, setIsOpenVideo] = useState<boolean>(false);

  const { language } = useLanguage();
  const { isDesktop, isPhone, mediaQuery } = useContextMediaQuery();

  const onOpenModal = () => setIsOpenVideo(true);
  const onCloseModal = () => setIsOpenVideo(false);

  const isLargeDesktop: boolean = mediaQuery === ESize.XL;

  return (
    <Box
      position={'relative'}
      zIndex={EZIndexName.OVER_DEFAULT}
      marginTop={isPhone ? '40px' : '80px'}
    >
      <Typography
        as={EActionAttribute.H2}
        variant={ETypographyVariant.H1}
        fontWeight={EFontWeight.BOLD}
        marginBottom={'24px'}
      >
        {t('home.secureYourSaving')}
      </Typography>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography
          variant={ETypographyVariant.H4}
          fontWeight={EFontWeight.REGULAR}
          maxWidth={'750px'}
        >
          <Trans
            i18nKey={`${Translate.common.WIDGET}:home.buyMetals`}
            components={{
              strong: (
                <Typography
                  as={EActionAttribute.SPAN}
                  variant={ETypographyVariant.H4}
                  fontWeight={EFontWeight.BOLD}
                />
              ),
            }}
          />
        </Typography>

        {isDesktop && (
          <Box
            top={isLargeDesktop ? '-56px' : '-50px'}
            position={'absolute'}
            width={'225px'}
            height={'249px'}
            right={isLargeDesktop ? '70px' : '-18px'}
            transform={'rotate(30deg)'}
          >
            <Image
              src={getStaticPathImage('homepage/pamp.png')}
              blurDataURL={getStaticPathImageBlurData(EFilenameBlur['homepage/pamp.png'])}
              alt={t('home.logosAlt')}
              width={225}
              height={249}
            />
          </Box>
        )}
      </Box>
      <Box margin={'80px -15px 0'}>
        <Grid isContainer>
          <SecureBlock
            titleKey={'buyPhysicalMetals'}
            text={t('home.weSelected')}
            link={ERouting.BUY}
            query={{ metal: metalToSlug[language][EMetal.GOLD] }}
            linkText={t('home.browseOurShop')}
            videoLink={BUY_PRECIOUS_METALS}
            videoTitle={t('home.howToBuy')}
            image={
              <Box
                position={'absolute'}
                top={'-92px'}
                width={'116px'}
                height={'185px'}
                right={'34px'}
                transform={'rotate(30deg)'}
              >
                <Image
                  src={getStaticPathImage('homepage/pamp.png')}
                  blurDataURL={getStaticPathImageBlurData(EFilenameBlur['homepage/pamp.png'])}
                  alt={t('home.logosAlt')}
                />
              </Box>
            }
          />

          <SecureBlock
            titleKey={'storeFree'}
            text={t('home.simplyChoose')}
            link={ERouting.STORAGE_SOLUTION}
            linkText={t('home.storageLink')}
            videoLink={STORE_PRECIOUS_METALS}
            videoTitle={t('home.howToStore')}
            image={
              <Box
                position={'absolute'}
                top={'-44px'}
                right={'11px'}
                height={'157px'}
                width={'143px'}
              >
                <Image
                  src={getStaticPathImage('gold-1g-front.png')}
                  blurDataURL={getStaticPathImageBlurData(EFilenameBlur['gold-1g-front.png'])}
                  alt={t('home.gold1gAlt')}
                />
              </Box>
            }
          />

          <SecureBlock
            titleKey={'resellNoCommission'}
            text={t('home.easilyManage')}
            link={ERouting.STORAGE_SOLUTION}
            query={{ id: 'manage-part' }}
            linkText={t('home.managePartLink')}
            videoLink={SELL_STORED_METALS}
            videoTitle={t('home.howToSell')}
            image={
              <Box
                position={'absolute'}
                top={'10px'}
                width={'102px'}
                height={'130px'}
                right={'29px'}
              >
                <Image
                  src={getStaticPathImage('gold-1g-back.png')}
                  blurDataURL={getStaticPathImageBlurData(EFilenameBlur['gold-1g-back.png'])}
                  alt={t('home.gold1gAlt')}
                />
              </Box>
            }
          />
        </Grid>
      </Box>
      <Box marginTop={isPhone ? '100px' : '78px'} display={'flex'} justifyContent={'space-between'}>
        <Box width={'100%'}>
          <Typography
            as={EActionAttribute.H3}
            variant={ETypographyVariant.H3}
            fontWeight={EFontWeight.REGULAR}
            marginBottom={'24px'}
          >
            <Trans
              i18nKey={`${Translate.common.WIDGET}:home.WereHereToHelp`}
              components={{
                strong: (
                  <Typography
                    as={EActionAttribute.SPAN}
                    variant={ETypographyVariant.H3}
                    fontWeight={EFontWeight.BOLD}
                  />
                ),
                br: <br />,
              }}
            />
          </Typography>

          <Box display={'flex'} justifyContent={isPhone ? 'flex-end' : 'flex-start'}>
            <Link
              as={EActionAttribute.H3}
              color={EColor.ACCENT}
              onClick={onOpenModal}
              startIcon={EIcon.PLAY_CIRCLED}
              variant={ETypographyVariant.BODY2}
              isIconFill={false}
              iconColor={EColor.ACCENT}
            >
              {t('home.whatIsGA')}
            </Link>
          </Box>

          <ModalVideo
            isOpen={isOpenVideo}
            onCloseModal={onCloseModal}
            videoLink={GA_BUY_SELL_STORE}
            title={'playVideoTitle'}
            language={language}
          />
        </Box>

        {!isPhone && (
          <Box display={'flex'} justifyContent={'center'} width={'100%'}>
            <Box position={'relative'} top={'19px'} width={'107px'} height={'100%'}>
              <Image
                src={getStaticPathImage('gold-1g-back.png')}
                blurDataURL={getStaticPathImageBlurData(EFilenameBlur['gold-1g-back.png'])}
                alt={t('home.gold1gAlt')}
                width={107}
                height={156}
              />
            </Box>

            <Box position={'relative'} top={'-38px'} left={'18px'} width={'131px'}>
              <Image
                src={getStaticPathImage('gold-1g-front.png')}
                blurDataURL={getStaticPathImageBlurData(EFilenameBlur['gold-1g-front.png'])}
                alt={t('home.gold1gAlt')}
                width={131}
                height={156}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box marginTop={isPhone ? '120px' : '164px'}>
        <Grid isContainer>
          <Grid md={6}>
            <Box
              position={'relative'}
              top={'0'}
              width={isPhone ? '334px' : '100%'}
              height={isDesktop ? '346px' : isLargeDesktop ? '420px' : '246px'}
              margin={'0 auto'}
            >
              <Image
                src={getStaticPathImage('homepage/logos.png')}
                blurDataURL={getStaticPathImageBlurData(EFilenameBlur['homepage/logos.png'])}
                alt={t('home.logosAlt')}
                sizes="(max-width: 768px) 300px, 20vw"
              />
            </Box>
          </Grid>

          <Grid md={6}>
            <Box paddingLeft={isLargeDesktop ? '16px' : '0'} height={'auto'} paddingTop={'24px'}>
              <Typography
                as={EActionAttribute.H2}
                variant={ETypographyVariant.H1}
                fontWeight={EFontWeight.BOLD}
                marginBottom={'24px'}
              >
                {t('home.trustExpert')}
              </Typography>

              <Typography
                variant={ETypographyVariant.BODY1}
                fontWeight={EFontWeight.REGULAR}
                marginBottom={'24px'}
                maxWidth={'460px'}
              >
                <Trans
                  i18nKey={`${Translate.common.WIDGET}:home.gaIsOfficial`}
                  components={{
                    strong: (
                      <Typography
                        as={EActionAttribute.SPAN}
                        variant={ETypographyVariant.BODY1}
                        fontWeight={EFontWeight.BOLD}
                      />
                    ),
                    br: <br />,
                  }}
                />
              </Typography>

              <LinkTranslatedStyledAsLink
                keyRoute={ERouting.ABOUT_US}
                color={EColor.ACCENT}
                endIcon={EIcon.ARROW_DOWN}
                iconPosition={EPosition.RIGHT}
                iconColor={EColor.SECONDARY}
                animation={ELinkAnimation.ARROW_RIGHT}
                iconOrientation={EPosition.RIGHT}
                isIconStroke
              >
                {t('home.learnMore')}
              </LinkTranslatedStyledAsLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SecureSavingsBlock = withTranslation(
  SecureSavingsBlockContainer,
  Translate.common.WIDGET,
);
