import { useRef } from 'react';

export function usePreviousDistinct<T>(value: T): T {
  const prevRef = useRef<T>();
  const curRef = useRef(value);

  if (curRef.current !== value) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
}
