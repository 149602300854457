import styled, { css } from 'styled-components';

import { Box, CopyToClipboard, EditorContent, Icon, Typography } from '@core/component';
import { useQueryApi } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  EEditor,
  EIcon,
  ESize,
  ETypographyVariant,
  WithThemeProps,
} from '@core/type';
import { DiscountEntity, EQueryKey } from '@core/type/api';
import { getPaletteHandlers } from '@core/util';

const InnerDiv = styled(Box)(({
  theme: {
    palette,
    structure: {
      discountBanner: { padding },
    },
  },
}: WithThemeProps) => {
  const { getColor } = getPaletteHandlers(palette);

  return css`
    width: 100%;
    background-color: ${getColor({ commonType: EColor.BLUE, intensity: EColor.R100 })};
    text-align: center;
    border-radius: 8px;
    padding: ${padding};
  `;
});

export const DiscountBanner = () => {
  const { data: discount, isSuccess } = useQueryApi<DiscountEntity>(EQueryKey.DISCOUNT);

  return (
    isSuccess &&
    discount?.name && (
      <Box textAlign="center" display="flex" margin="4px auto 16px">
        <InnerDiv>
          <Icon
            type={EIcon.DISCOUNT}
            display="inline-block"
            size={ESize.MD}
            verticalAlign="middle"
            color={EColor.ACCENT}
            marginRight="10px"
          />

          <EditorContent
            display="inline-block"
            type={EEditor.INLINE}
            content={discount.bannerText}
            marginRight="8px"
            color={EColor.ACCENT}
          />
          <CopyToClipboard>
            <Typography
              textDecoration="underline"
              as={EActionAttribute.SPAN}
              variant={ETypographyVariant.H6}
              textUnderlineOffset="4px"
              color={EColor.ACCENT}
            >
              {discount.name}
            </Typography>
          </CopyToClipboard>
        </InnerDiv>
      </Box>
    )
  );
};
