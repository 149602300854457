import { ChartTradingViewProps } from 'core-component/src/chart/interface-charts';

import { getChartTradingView, getIFrameYoutubeComponent, Tweet, Typography } from '@core/component';
import { ELiveChartsPeriod, EMetal, WeightUnitType } from '@core/type';
import { EFAQCategory } from '@core/type/api';
import {
  ESanityWidget,
  GetSanityComponentHandler,
  SanityImage,
  SanityVideo,
  SanityWidget,
} from '@core/type/sanity';
import {
  CarouselBanners,
  DiscountBanner,
  getButtonGroupComponent,
  getCarouselProductGroup,
  getGuideBlockComponent,
  getMarginComponent,
  getPortableTextComponent,
  getRatingStarsComponent,
  getWidgetContainer,
  getWidgetGrid,
  getWidgetTableSpotPriceDailyStats,
  getWidgetTableSpotPriceHistory,
  TabProduct,
  TabWidget,
  SuggestedProducts,
  WidgetBlogArticles,
  WidgetChart,
  WidgetPageRating,
} from '@core/widget';

import { ViewChartsWrapper } from '../../view/charts/ViewChartsWrapper';
import { ViewInvestmentCharts } from '../../view/charts/ViewInvestmentCharts';
import { ArticlesBlock } from '../../view/home/components/ArticlesBlock';
import { HomepageFaqBlock } from '../../view/home/components/HomepageFaqBlock';
import { getHomepageTrustpilot } from '../../view/home/components/HomepageTrustpilot';
import { InvestmentAssistantBlock } from '../../view/home/components/InvestmentAssistantBlock';
import { ProductsBlock } from '../../view/home/components/ProductsBlock';
import { SecureSavingsBlock } from '../../view/home/components/SecureSavingsBlock';
import { InvestmentAssistantBanner } from '../../view/product/shop/InvestmentAssistantBanner';
import { getImageBlockComponent } from './image-block';

export const PageBuilder = ({
  components,
  pageId,
}: {
  components: SanityWidget[];
  pageId: string;
}) => {
  return <>{components?.map((section) => getSanityComponent(section, pageId))}</>;
};

export const getSanityComponent: GetSanityComponentHandler = (section, pageId) => {
  switch (section._type) {
    case ESanityWidget.PRODUCTS:
      return (
        <ProductsBlock metalIso={section.apiParams?.metalIso as EMetal.GOLD | EMetal.SILVER} />
      );
    case ESanityWidget.ARTICLES:
      return <ArticlesBlock />;
    case ESanityWidget.BLOG_ARTICLE:
      return <WidgetBlogArticles slug={section.apiParams.slug} />;
    case ESanityWidget.TEXT_EDITOR:
      return <>{getPortableTextComponent(section)}</>;
    case ESanityWidget.FAQ:
      return <HomepageFaqBlock category={section.apiParams?.category as EFAQCategory} />;
    case ESanityWidget.CAROUSEL_TRUSTPILOT:
      return getHomepageTrustpilot(section);
    case ESanityWidget.DISCOUNT_BANNER:
      return <DiscountBanner />;
    case ESanityWidget.CAROUSEL_PRODUCT:
      return <> {getCarouselProductGroup(section)}</>;
    case ESanityWidget.CAROUSEL_BANNER:
      return <CarouselBanners bannerSlug={section?.apiParams?.bannerSlug} />;
    case ESanityWidget.CHARTS:
      return (
        <ViewChartsWrapper
          metal={section.apiParams?.metal as EMetal}
          weightUnit={section.apiParams?.weightUnit as WeightUnitType}
        >
          <WidgetChart
            metal={section.apiParams?.metal as EMetal}
            period={section.apiParams?.period as ELiveChartsPeriod}
            shouldChangeUrl={false}
          />
        </ViewChartsWrapper>
      );
    case ESanityWidget.CHARTS_INVESTMENT:
      return <ViewInvestmentCharts />;
    case ESanityWidget.TABLE_SPOT_PRICE_DAILY_STAT:
      return <>{getWidgetTableSpotPriceDailyStats(section)}</>;
    case ESanityWidget.TABLE_SPOT_PRICE_HISTORY:
      return <> {getWidgetTableSpotPriceHistory(section)}</>;
    case ESanityWidget.INVESTMENT_ASSISTANT:
      return <InvestmentAssistantBlock />;
    case ESanityWidget.SECURE_SAVING:
      return <SecureSavingsBlock />;
    case ESanityWidget.BUTTON_GROUP:
      return <>{getButtonGroupComponent(section)}</>;
    case ESanityWidget.SUGGESTED_PRODUCTS:
      return <SuggestedProducts slug={(section as unknown as { slug: string }).slug} />;
    case ESanityWidget.MARGIN:
      return <> {getMarginComponent(section)}</>;
    case ESanityWidget.RATING_STARS:
      return <>{getRatingStarsComponent(section, pageId)}</>;
    case ESanityWidget.PAGE_RATING:
      return <WidgetPageRating pageId={pageId} />;
    case ESanityWidget.GUIDE_BLOCK:
      return <>{getGuideBlockComponent(section)}</>;
    case ESanityWidget.IMAGE:
      return <>{getImageBlockComponent(section as unknown as SanityImage)}</>;
    case ESanityWidget.CONTAINER:
      return <>{getWidgetContainer(section, pageId, getSanityComponent)}</>;
    case ESanityWidget.GRID:
      return <>{getWidgetGrid(section, pageId, getSanityComponent)}</>;
    case ESanityWidget.PRODUCT_TABS:
      return <TabWidget tabs={(section as SanityWidget & { tabs: TabProduct[] }).tabs} />;
    case ESanityWidget.CHART_TRADING:
      return (
        <>
          {getChartTradingView({
            ...section,
            symbol: (section as unknown as ChartTradingViewProps).symbol,
            interval: (section as unknown as ChartTradingViewProps).interval,
            timezone: (section as unknown as ChartTradingViewProps).timezone,
            theme: (section as unknown as ChartTradingViewProps).theme,
            style: (section as unknown as ChartTradingViewProps).style,
            locale: (section as unknown as ChartTradingViewProps).locale,
            supportHost: (section as unknown as ChartTradingViewProps).supportHost,
          })}
        </>
      );
    case ESanityWidget.INVESTMENT_ASSISTANT_BANNER:
      return <InvestmentAssistantBanner />;
    case ESanityWidget.TWEET:
      return <Tweet id={(section as unknown as { id: string }).id} />;
    case ESanityWidget.VIDEO:
      return <>{getIFrameYoutubeComponent(section as unknown as SanityVideo)}</>;
    default:
      return <Typography>Invalid section {section._type}</Typography>;
  }
};
