import { Box, Icon, Typography } from '@core/component';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, EIcon, ESize, ETypographyVariant } from '@core/type';
import { formatCurrency } from '@core/util';

const ROUNDING = 2;

export const PriceChange = ({
  priceChange,
  isPositive,
  percentage,
}: {
  priceChange: number;
  isPositive: boolean;
  percentage: {
    number: number;
  };
}) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const color = isPositive ? EColor.SUCCESS : EColor.DANGER;

  return (
    <>
      {priceChange !== 0 && (
        <Box>
          <Icon
            display="inline-block"
            type={EIcon.TRIANGLE}
            transform={isPositive ? 'rotate(180deg)' : undefined}
            color={color}
            size={ESize.XXXS}
            marginRight="5px"
          />
          <Typography
            display="inline-block"
            color={color}
            variant={ETypographyVariant.BODY2}
            lineHeight="14px"
            marginRight="8px"
          >
            {formatCurrency(language, currency, priceChange, { signDisplay: 'never' })}
          </Typography>
          <Typography
            display="inline-block"
            color={color}
            variant={ETypographyVariant.BODY2}
            lineHeight="14px"
          >
            {`(${isPositive ? '+' : '-'}${percentage.number.toFixed(ROUNDING)}%)`}
          </Typography>
        </Box>
      )}
    </>
  );
};
