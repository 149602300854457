import { Box, ImageApi, Typography } from '@core/component';
import { EColor, ETypographyVariant } from '@core/type';

import { Form } from './Form';
import { LeadMagnetComponentProps } from './interface-lead-magnet';

export const LeadMagnet = ({ requestPage, leadMagnet }: LeadMagnetComponentProps) => {
  return (
    leadMagnet && (
      <Box
        backgroundColor={{ commonType: EColor.YELLOW, intensity: EColor.R100 }}
        overflow={'hidden'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'100%'}
        borderRadius={'16px'}
      >
        <Box padding={'24px 32px 8px'}>
          <Typography variant={ETypographyVariant.H4} textAlign={'center'} marginBottom={'8px'}>
            {leadMagnet.title}
          </Typography>
          <Typography variant={ETypographyVariant.BODY2} textAlign={'center'} marginBottom={'16px'}>
            {leadMagnet.subtitle}
          </Typography>

          <Form buttonLabel={leadMagnet.buttonLabel} requestPage={requestPage} />
        </Box>
        <Box height={'160px'} width={'100%'} position={'relative'}>
          <ImageApi
            {...leadMagnet.image}
            style={{ objectFit: 'cover' }}
            sizes="(max-width: 768px) 100vw, 15vw"
          />
        </Box>
      </Box>
    )
  );
};
