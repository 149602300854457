import useTranslation from 'next-translate/useTranslation';

import { Box, Icon, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EIcon, ETypographyVariant } from '@core/type';

export const ChartDescription = () => {
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);
  const { isMobile } = useContextMediaQuery();

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={isMobile ? 'column' : 'row'}
        gap={isMobile ? '4px' : '20px'}
      >
        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          <Icon type={EIcon.LINE_SOLID} />
          <Typography variant={ETypographyVariant.CAPTION3} fontStyle={'italic'}>
            {tWidget('ifIHadInvested.performance')}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'16px'}>
          <Icon type={EIcon.LINE_DASHED} />
          <Typography variant={ETypographyVariant.CAPTION3} fontStyle={'italic'}>
            {tWidget('ifIHadInvested.forecastBased')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
