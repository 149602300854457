import { EPosition, PositionType, StyleRuleAlignContent } from '@core/type';
import { SanityLinkObjProps } from '@core/type/sanity';

export type ButtonGroupPosition = keyof PositionType<
  EPosition.LEFT | EPosition.CENTER | EPosition.RIGHT
>;

export type ButtonGroupEntity = {
  align?: ButtonGroupPosition;
  buttons: SanityLinkObjProps[];
};

export type ButtonGroupAlignValues = {
  [key in ButtonGroupPosition]: StyleRuleAlignContent;
};

export const justifyContentAlign: ButtonGroupAlignValues = {
  [EPosition.LEFT]: 'flex-start',
  [EPosition.CENTER]: 'center',
  [EPosition.RIGHT]: 'flex-end',
};
