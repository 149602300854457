import { ECurrency, EPricing, PricingValuesType } from '@core/type';
import { CurrencyRateEntity } from '@core/type/api';

// Pricing, in CHF
export const pricing: PricingValuesType = {
  [EPricing.STORAGE_100]: 100,
  [EPricing.STORAGE_10K]: 10000,
  [EPricing.STORAGE_100K]: 100000,
  [EPricing.LIMIT_15K]: 15000,
  [EPricing.LIMIT_100K]: 100000,
  [EPricing.PAY_PER_MONTH]: 8,
  [EPricing.MANUAL_RATE]: 1,
  [EPricing.DYNAMIC_RATE]: 1,
};

export const defaultCurrencyRates: CurrencyRateEntity = {
  currencyIso: ECurrency.CHF,
  manualRate: pricing[EPricing.MANUAL_RATE],
  dynamicRate: pricing[EPricing.DYNAMIC_RATE],
};

export const SURCHARGE_PERCENT = 3;
export const DEFAULT_AMOUNT_VALUE = 5000;
