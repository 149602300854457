import { ESize, SizeType } from './size';

export type MarginSize = keyof SizeType<ESize.XXS | ESize.XS | ESize.SM | ESize.MD | ESize.LG>;

export type MarginSizeValues = {
  [key in MarginSize]: string;
};

export const marginSize: MarginSizeValues = {
  [ESize.XXS]: '16px',
  [ESize.XS]: '24px',
  [ESize.SM]: '48px',
  [ESize.MD]: '64px',
  [ESize.LG]: '104px',
};

export type MarginProps = {
  size?: MarginSize;
};
