import { PortableText } from '@portabletext/react';
import { EProductSecificitiy } from 'core-sanity/src/constants/productSpecificity';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useState } from 'react';
import { PortableTextBlock } from 'sanity';

import { Accordion, Box, Container, Grid, Tab, TabPanels, Tabs, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import {
  EAccordionClosingType,
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ESize,
  ETypographyVariant,
  EUnit,
  TabsChangeEvent,
} from '@core/type';
import { ProductSpecificitiesEntity } from '@core/type/api';
import { ESanityField } from '@core/type/sanity';
import { formatUnitShort } from '@core/util';

import { LineSpecification } from './LineSpecification';
import { TabPanelProductDetails } from './TabPanelProductDetails';

type SanityProductSpecificitiesEntity = ProductSpecificitiesEntity & {
  [EProductSecificitiy.WEIGHT_OZ]: number;
  [EProductSecificitiy.WEIGHT_G]: number;
};

export type TabProduct = {
  _type: string;
  _key: string;
  title: string;
  content: PortableTextBlock | SanityProductSpecificitiesEntity;
};

export const TabWidget = ({ tabs }: { tabs: TabProduct[] }) => {
  const { isPhone, isMobile } = useContextMediaQuery();
  const { language } = useLanguage();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { t } = useTranslation(Translate.common.WIDGET);

  const handleTabs = ({ target: { value } }: TabsChangeEvent<number>) => {
    setCurrentTab(value);
  };

  return (
    <Container
      as={EActionAttribute.SECTION}
      gutter={0}
      maxWidth={'946px'}
      marginBottom={isMobile ? '64px' : '104px'}
    >
      {isPhone ? (
        <Box display={'flex'} flexDirection={'column'} rowGap={'16px'}>
          {tabs.map((tab) => {
            const diameterProps =
              (tab.content as SanityProductSpecificitiesEntity).diameter &&
              formatUnitShort(
                language,
                EUnit.MILLIMETER,
                +(tab.content as SanityProductSpecificitiesEntity).diameter,
              );

            const lengthProps =
              (tab.content as SanityProductSpecificitiesEntity).length &&
              formatUnitShort(
                language,
                EUnit.MILLIMETER,
                +(tab.content as SanityProductSpecificitiesEntity).length,
              );

            const widthProps =
              (tab.content as SanityProductSpecificitiesEntity).width &&
              formatUnitShort(
                language,
                EUnit.MILLIMETER,
                +(tab.content as SanityProductSpecificitiesEntity).width,
              );

            return tab._type === (ESanityField.PORTABLE_TEXT as string) ? (
              <AccordionProductDetails
                key={tab._key}
                title={tab.title}
                content={<PortableText value={tab.content as PortableTextBlock} />}
              />
            ) : (
              <AccordionProductDetails
                title={tab.title}
                key={tab._key}
                content={
                  <>
                    <LineSpecification
                      title={t('tabProduct.specifications.productId')}
                      value={(tab.content as SanityProductSpecificitiesEntity).sku}
                    />
                    <LineSpecification
                      title={t('tabProduct.specifications.year')}
                      value={(tab.content as SanityProductSpecificitiesEntity).issueYear}
                    />
                    <LineSpecification
                      title={t('tabProduct.specifications.mintMark')}
                      value={(tab.content as SanityProductSpecificitiesEntity).mintMark}
                    />
                    <LineSpecification
                      title={t('tabProduct.specifications.thickness')}
                      value={(tab.content as SanityProductSpecificitiesEntity).thickness}
                    />
                    <LineSpecification
                      title={t('tabProduct.specifications.purity')}
                      value={(tab.content as SanityProductSpecificitiesEntity).purity}
                    />
                    <LineSpecification
                      key={EUnit.GRAM}
                      title={t(`tabProduct.specifications.${EUnit.GRAM}`)}
                      value={formatUnitShort(
                        language,
                        EUnit.GRAM,
                        (tab.content as SanityProductSpecificitiesEntity).weight_g,
                      )}
                    />
                    <LineSpecification
                      key={EUnit.OUNCE}
                      title={t(`tabProduct.specifications.${EUnit.OUNCE}`)}
                      value={formatUnitShort(
                        language,
                        EUnit.OUNCE,
                        (tab.content as SanityProductSpecificitiesEntity).weight_oz,
                      )}
                    />
                    <LineSpecification
                      title={t('tabProduct.specifications.diameter')}
                      value={diameterProps}
                    />
                    <LineSpecification
                      title={t('tabProduct.specifications.length')}
                      value={lengthProps}
                    />
                    <LineSpecification
                      title={t('tabProduct.specifications.width')}
                      value={widthProps}
                      hasLine={false}
                    />
                  </>
                }
              />
            );
          })}
        </Box>
      ) : (
        <>
          <Tabs<number> value={currentTab} onChange={handleTabs}>
            {tabs.map((tab, index) => (
              <Tab
                value={index}
                hasBoxShadow={false}
                key={tab._key}
                lineActiveColor={EColor.ACCENT}
                backgroundActiveColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
                childrenActive={
                  <Typography
                    as={EActionAttribute.H2}
                    variant={ETypographyVariant.H6}
                    color={EColor.ACCENT}
                  >
                    {tab.title}
                  </Typography>
                }
              >
                <Typography
                  as={EActionAttribute.H2}
                  variant={ETypographyVariant.H6}
                  color={EColor.PRIMARY}
                >
                  {tab.title}
                </Typography>
              </Tab>
            ))}
          </Tabs>
          <TabPanels<number> value={currentTab}>
            {tabs.map((tab, index) => {
              const diameterProps =
                (tab.content as SanityProductSpecificitiesEntity).diameter &&
                formatUnitShort(
                  language,
                  EUnit.MILLIMETER,
                  +(tab.content as SanityProductSpecificitiesEntity).diameter,
                );

              const lengthProps =
                (tab.content as SanityProductSpecificitiesEntity).length &&
                formatUnitShort(
                  language,
                  EUnit.MILLIMETER,
                  +(tab.content as SanityProductSpecificitiesEntity).length,
                );

              const widthProps =
                (tab.content as SanityProductSpecificitiesEntity).width &&
                formatUnitShort(
                  language,
                  EUnit.MILLIMETER,
                  +(tab.content as SanityProductSpecificitiesEntity).width,
                );

              return (
                <TabPanelProductDetails value={index} key={tab._key}>
                  {tab._type === (ESanityField.PORTABLE_TEXT as string) ? (
                    <PortableText value={tab.content as PortableTextBlock} />
                  ) : (
                    <Grid isContainer columnSpacing={114}>
                      <Grid md={6}>
                        <LineSpecification
                          title={t('tabProduct.specifications.productId')}
                          value={(tab.content as SanityProductSpecificitiesEntity).sku}
                        />
                        <LineSpecification
                          title={t('tabProduct.specifications.year')}
                          value={(tab.content as SanityProductSpecificitiesEntity).issueYear}
                        />
                        <LineSpecification
                          title={t('tabProduct.specifications.mintMark')}
                          value={(tab.content as SanityProductSpecificitiesEntity).mintMark}
                        />
                        <LineSpecification
                          title={t('tabProduct.specifications.thickness')}
                          value={(tab.content as SanityProductSpecificitiesEntity).thickness}
                        />
                        <LineSpecification
                          title={t('tabProduct.specifications.purity')}
                          value={(tab.content as SanityProductSpecificitiesEntity).purity}
                        />
                      </Grid>
                      <Grid md={6}>
                        <LineSpecification
                          key={EUnit.GRAM}
                          title={t(`tabProduct.specifications.${EUnit.GRAM}`)}
                          value={formatUnitShort(
                            language,
                            EUnit.GRAM,
                            (tab.content as SanityProductSpecificitiesEntity).weight_g,
                          )}
                        />
                        <LineSpecification
                          key={EUnit.OUNCE}
                          title={t(`tabProduct.specifications.${EUnit.OUNCE}`)}
                          value={formatUnitShort(
                            language,
                            EUnit.OUNCE,
                            (tab.content as SanityProductSpecificitiesEntity).weight_oz,
                          )}
                        />
                        <LineSpecification
                          title={t('tabProduct.specifications.diameter')}
                          value={diameterProps}
                        />
                        <LineSpecification
                          title={t('tabProduct.specifications.length')}
                          value={lengthProps}
                        />
                        <LineSpecification
                          title={t('tabProduct.specifications.width')}
                          value={widthProps}
                        />
                      </Grid>
                    </Grid>
                  )}
                </TabPanelProductDetails>
              );
            })}
          </TabPanels>
        </>
      )}
    </Container>
  );
};

const AccordionProductDetails = ({ title, content }: { title: ReactNode; content: ReactNode }) => {
  return (
    <Accordion
      titleContent={
        <Typography
          as={EActionAttribute.H2}
          variant={ETypographyVariant.H5}
          fontWeight={EFontWeight.MEDIUM}
        >
          {title}
        </Typography>
      }
      titleActiveContent={
        <Typography
          variant={ETypographyVariant.H5}
          fontWeight={EFontWeight.MEDIUM}
          color={EColor.ACCENT}
        >
          {title}
        </Typography>
      }
      titlePadding={'20px'}
      borderRadius={'10px'}
      iconSize={ESize.SM}
      defaultIcon={EIcon.PLUS}
      activeIcon={EIcon.MINUS}
      iconActiveColor={EColor.ACCENT}
      backgroundColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
      closingType={EAccordionClosingType.FULL_CLICK}
    >
      <Box padding={'0 20px 20px'}>{content}</Box>
    </Accordion>
  );
};
