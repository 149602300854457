import type { ReactNode } from 'react';

import { weightUnitContext } from '@core/context';
import { WeightUnitType } from '@core/type';
import { WeightUnitHook, WeightUnitState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useWeightUnit } from '../hook';

export const ProviderWeightUnit = ({
  children,
  providerWeightUnitInitialize,
}: {
  children?: ReactNode;
  providerWeightUnitInitialize?: WeightUnitType;
}) => {
  const hookWeightUnit = storeHook<WeightUnitState, WeightUnitHook>(
    {
      weightUnit: providerWeightUnitInitialize,
    },
    useWeightUnit,
  );

  return <weightUnitContext.Provider value={hookWeightUnit}>{children}</weightUnitContext.Provider>;
};
