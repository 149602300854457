import { Datetime, ECurrency, EMetal, EUnit, Price } from '../interface';

export enum ETableSpotPriceCategory {
  CURRENCIES = 'currencies',
  WEIGHT = 'weight',
  CARATS = 'carats',
}

export enum ETableSpotWeights {
  GRAM = 'GRAM',
  GRAM5 = 'GRAM_5',
  GRAM10 = 'GRAM10',
  OUNCE = 'OUNCE',
  GRAM100 = 'GRAM100',
  OUNCE5 = 'OUNCE_5',
  OUNCE10 = 'OUNCE10',
  KILO = 'KILO',
  OUNCE100 = 'OUNCE100',
  KILO5 = 'KILO_5',
  KILO10 = 'KILO10',
  KILO100 = 'KILO100',
  TON = 'TON',
}

const GRAMS_PER_OUNCE = 28.3495;
const GRAMS_PER_KILO = 1000;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const GRAMS_PER_TON = 1000 * 1000;

const WEIGHT5 = 5;
const WEIGHT10 = 10;
const WEIGHT100 = 100;

export const tableSpotWeightToGramWeight = {
  [ETableSpotWeights.GRAM]: 1,
  [ETableSpotWeights.GRAM5]: WEIGHT5,
  [ETableSpotWeights.GRAM10]: WEIGHT10,
  [ETableSpotWeights.GRAM100]: WEIGHT100,
  [ETableSpotWeights.OUNCE]: GRAMS_PER_OUNCE,
  [ETableSpotWeights.OUNCE5]: WEIGHT5 * GRAMS_PER_OUNCE,
  [ETableSpotWeights.OUNCE10]: WEIGHT10 * GRAMS_PER_OUNCE,
  [ETableSpotWeights.OUNCE100]: WEIGHT100 * GRAMS_PER_OUNCE,
  [ETableSpotWeights.KILO]: GRAMS_PER_KILO,
  [ETableSpotWeights.KILO5]: WEIGHT5 * GRAMS_PER_KILO,
  [ETableSpotWeights.KILO10]: WEIGHT10 * GRAMS_PER_KILO,
  [ETableSpotWeights.KILO100]: WEIGHT100 * GRAMS_PER_KILO,
  [ETableSpotWeights.TON]: GRAMS_PER_TON,
};

export enum ETableSpotPriceCurrency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF',
  AUD = 'AUD',
  CAD = 'CAD',
  CNY = 'CNY',
  INR = 'INR',
  JPY = 'JPY',
}

export enum ETableSpotPriceCarat {
  CT_9 = 9,
  CT_10 = 10,
  CT_14 = 14,
  CT_16 = 16,
  CT_18 = 18,
  CT_21 = 21,
  CT_22 = 22,
  CT_24 = 24,
}

export type TableSpotPriceRow = {
  currentPrice: Price;
  dayMin: Price;
  dayMax: Price;
  dayAverage: Price;
  dayPerformance: number;
};

export type FormattedTableSpotPriceRow = {
  currentPrice: string;
  dayMin: string;
  dayMax: string;
  dayAverage: string;
  dayPerformance: number;
};

type SpotPriceDailyStatsRequestBase = {
  metalIso: EMetal;
};

export type SpotPriceWeightUnitType = EUnit.GRAM | EUnit.KILOGRAM | EUnit.OUNCE | EUnit.TONNE;

export type SpotPriceCaratsRequest = SpotPriceDailyStatsRequestBase & {
  currencyIso: ECurrency;
  weightUnit: SpotPriceWeightUnitType;
};

export type SpotPriceCurrenciesRequest = SpotPriceDailyStatsRequestBase & {
  weightUnit: SpotPriceWeightUnitType;
};

export type SpotPriceWeightsRequest = SpotPriceDailyStatsRequestBase & {
  currencyIso: ECurrency;
};

type SpotPriceDailyStatsResponseBase<T extends string | number> = {
  metalIso: EMetal;
  table: Record<T, TableSpotPriceRow>;
  createdAt: Datetime;
};

export type SpotPriceCaratsResponse = SpotPriceDailyStatsResponseBase<ETableSpotPriceCarat> &
  Pick<SpotPriceCaratsRequest, 'currencyIso' | 'weightUnit'>;

export type SpotPriceCurrenciesResponse = SpotPriceDailyStatsResponseBase<ETableSpotPriceCurrency> &
  Pick<SpotPriceCurrenciesRequest, 'weightUnit'>;

export type SpotPriceWeightsResponse = SpotPriceDailyStatsResponseBase<SpotPriceWeightUnitType> &
  Pick<SpotPriceWeightsRequest, 'currencyIso'>;
